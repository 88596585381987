import { Components, Theme } from '@mui/material';
import { grey, blue, blueGrey, cyan, lightBlue, teal } from '@mui/material/colors';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        dashed: true;
        clue: true;
        backbutton: true;
        greyClue: true;
        primaryContained: true;
        imageUpload: true;
        greyBorderButton: true;
    }
}

export function Button(theme: Theme): Components {
    return {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'greyBorderButton' },
                    style: {
                        textTransform: 'none',
                        // border: `2px solid ${blueGrey[200]}`,
                        border: '2px solid #374653',
                        borderRadius: '12px',
                        color: '#374653',
                        background: '#FFFFFF'
                    }
                },
                {
                    props: { variant: 'imageUpload' },
                    style: {
                        textTransform: 'none',
                        // border: `2px solid ${blueGrey[200]}`,
                        border: '1px solid #ACBCC7',
                        borderRadius: '5px',
                        color: lightBlue[900]
                    }
                },
                {
                    props: { variant: 'dashed' },
                    style: {
                        textTransform: 'none',
                        border: `2px solid ${lightBlue[900]}`,
                        borderRadius: '12px',
                        color: blueGrey[800]
                    }
                },
                {
                    props: { variant: 'clue' },
                    style: {
                        textTransform: 'none',
                        borderRadius: '4px',
                        color: '#009F54',
                        backgroundColor: '#00DF761A',
                        '&:hover': {
                            backgroundColor: '#00DF76'
                        }
                    }
                },
                {
                    props: { variant: 'greyClue' },
                    style: {
                        textTransform: 'none',
                        borderRadius: '4px',
                        color: grey[300],
                        backgroundColor: grey[50]
                    }
                },
                {
                    props: { variant: 'backbutton' },
                    style: {
                        textTransform: 'none',
                        borderRadius: '4px',
                        color: blueGrey[800],
                        backgroundColor: theme.palette.background.default,
                        border: `1px solid ${blueGrey[200]}`,
                        height: 'auto'
                    }
                },
                {
                    props: { variant: 'primaryContained' },
                    style: {
                        textTransform: 'none',
                        borderRadius: '8px',
                        color: 'white',
                        backgroundColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark
                        }
                    }
                }
            ],
            styleOverrides: {
                root: {
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
                sizeMedium: {
                    height: 48
                },
                sizeLarge: {
                    height: 56
                },
                contained: {
                    color: theme.palette.secondary.main,
                    backgroundColor: blue[100],
                    '&:hover': {
                        backgroundColor: blue[200]
                    }
                },
                outlined: {
                    border: `1px solid ${theme.palette.primary}`,
                    borderRadius: '12px',
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                },
                text: {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                }
            }
        }
    };
}
