import axios from 'axios';
import { API_URL } from '../config';

const url = API_URL + '/api/establishment';

export const getSenior = async (token: string): Promise<any> =>
    axios
        .get(`${url}/senior`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            // console.log(error);
            return null;
        });

export const getPro = async (token: string): Promise<any> =>
    axios
        .get(`${url}/pro`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data;
        });

export const getEstablishments = async (token: string): Promise<any> =>
    axios
        .get(`${url}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data;
        });

export const getEstablishment = async (token: string, id: string): Promise<any> =>
    axios
        .get(`${url}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data;
        });
