import styled from 'styled-components';

export const Content = styled.div`
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 850px) {
        max-width: 90%;
    }
    @media (min-width: 1000px) {
        max-width: 80%;
    }
    @media (min-width: 1200px) {
        max-width: 70%;
    }
`;
