import { WithTranslation, withTranslation } from 'react-i18next';
import style from './style.module.css';
import { useForm } from 'react-hook-form';
import UploadInput from '../UploadInput';
import FormGroup from '../FormGroup';
import Input from '../Input/index';
import ButtonsContainer from '../ButtonsContainer';
import Button from '../Button/index';
import { useEffect } from 'react';

interface ImportVideoProps extends WithTranslation {
    onReturn: () => void;
    onImport: (data: any) => void;
    onChangeTitle?: (title: string) => void;
    data: any;
};

const ImportVideo = ({ t, onReturn, onChangeTitle, onImport, data }: ImportVideoProps) => {
    const {
        watch,
        register,
        control,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = useForm<{ video: any, fileName: string }>({
        mode: 'all',
        defaultValues: {
            video: data?.video || [],
            fileName: data?.filename || '',
        },
    });

    const onSubmit = (data: any) => {
        onImport([{
            url: data.video[0].url,
            fileName: data.fileName,
            thumbnail: data.video[0].thumbnail,
        }]);
    };

    const video = watch('video');
    const fileName = watch('fileName');

    useEffect(() => {
        if (video.length && !fileName) {
            setValue('fileName', video[0].fileName, { shouldValidate: true });
        }
    }, [video]);

    return (
        <>
            <div className={style.subTitle}>{t('Importer une vidéo depuis votre ordinateur ou smartphone.')}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup required>
                    <UploadInput
                        type='VIDEO'
                        control={control}
                        register={register('video', { required: true })}
                        pathStorage='videos'
                    />
                </FormGroup>
                
                {!!video.length && <FormGroup label={t('Saisissez un titre cours pour votre vidéo') as string} error={errors.fileName} required>
                    <Input register={register('fileName', { required: true })} />
                </FormGroup>}

                <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                    <Button size='LARGE' theme='SECONDARY' disabled={!isValid} action={handleSubmit(onSubmit)} label={t('Envoyer')} />
                </ButtonsContainer>
            </form>
        </>
    );
};

export default withTranslation()(ImportVideo);