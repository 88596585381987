import { User } from '../../models/User';
import ProfilePicture from '../ProfilePicture';
import style from './style.module.css';
import { Patient } from '../../models/Patient';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface ListMembersProps {
    members: User[] | Patient[];
    onMemberClick?: (member: User) => void;
};

const ListMembers = ({ members, onMemberClick }: ListMembersProps) => {

    const getInitials = (member: User) => {
        const firstName = member.firstName;
        const lastName = member.lastName
        return (firstName?.charAt(0).toUpperCase() ?? '') + (lastName?.charAt(0).toUpperCase() ?? '');
    };

    return (
        <div className={style.members}>
            {members.map((member) => (
                <div key={member.id} className={`${style.member} ${onMemberClick ? style.onClick : ''}`} onClick={() => onMemberClick?.(member)}>
                    <div className={style.profilePictureContainer}>
                        <ProfilePicture picture={member.profilePhoto as string} initials={getInitials(member)} />
                    </div>
                    <div className={style.memberName}>{member.firstName} {member.lastName}</div>
                    {onMemberClick &&
                        <div className={style.cta}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>}
                </div>
            ))}
        </div>
    );
};

export default ListMembers;