export interface Word {
    word: string;
    letters: string[];
    funFact?: string;
};

export interface DragItem {
    id: number
    top: number
    left: number
    letter: string
    handIdx?: number
    zIndex: number
    status: 'idle' | 'correct' | 'wrong' | 'glow' | 'placed'
}

export const levels: Record<number, Word[]> = {
    1: [
        { word: 'Air', letters: ['A', 'I', 'R'], funFact: `Le mélange de gaz qui forme l'atmosphère terrestre et que nous respirons. Saviez-vous que l'air est composé d'environ 78% d'azote, 21% d'oxygène et 1% d'autres gaz, dont le dioxyde de carbone et l'argon ?` },
        { word: 'Ami', letters: ['A', 'M', 'I'], funFact: `Une personne avec qui on entretient une relation d'affection et de confiance. Saviez-vous que les études montrent que les personnes ayant des amitiés solides ont tendance à être plus heureuses et en meilleure santé ?` },
        { word: 'Art', letters: ['A', 'R', 'T'], funFact: `L'expression créative de l'imagination, souvent sous forme de peinture, sculpture, musique, etc. Saviez-vous que la Joconde de Léonard de Vinci est l'un des tableaux les plus célèbres au monde, exposé au Musée du Louvre à Paris ?` },
        { word: 'Axe', letters: ['A', 'X', 'E'], funFact: `Une ligne de référence autour de laquelle quelque chose tourne ou est centré. Saviez-vous que la Terre tourne autour de son axe une fois par jour, créant ainsi le cycle du jour et de la nuit ?` },
        { word: 'Bar', letters: ['B', 'A', 'R'], funFact: `Un établissement où l'on sert des boissons alcoolisées. Saviez-vous que le plus vieux bar en activité du monde est le Sean's Bar en Irlande, qui existe depuis l'an 900 ?` },
        { word: 'Bas', letters: ['B', 'A', 'S'], funFact: `La partie inférieure de quelque chose ; aussi un vêtement porté sur les jambes. Saviez-vous que les bas en soie étaient considérés comme un symbole de luxe et d'élégance au 16ème siècle en Europe ?` },
        { word: 'Bec', letters: ['B', 'E', 'C'], funFact: `La partie dure et pointue de la bouche d'un oiseau. Saviez-vous que le bec du toucan est proportionnellement l'un des plus grands de tous les oiseaux, utilisé pour attraper des fruits et réguler la température corporelle ?` },
        { word: 'Bel', letters: ['B', 'E', 'L'], funFact: `Beau, agréable à regarder ou à écouter. Saviez-vous que le Taj Mahal, un mausolée en marbre blanc situé en Inde, est souvent considéré comme l'un des plus beaux édifices du monde ?` },
        { word: 'Beu', letters: ['B', 'E', 'U'], funFact: `Le bruit que font certains animaux, comme les moutons ou les chèvres. Saviez-vous que le bêlement d'une chèvre peut être entendu jusqu'à un kilomètre de distance ?` },
        { word: 'Bol', letters: ['B', 'O', 'L'], funFact: `Un récipient rond et profond utilisé pour manger ou servir de la nourriture. Saviez-vous que le mot "bol" est également utilisé dans le jargon du sport pour désigner un match très compétitif et intense ?` },
        { word: 'Box', letters: ['B', 'O', 'X'], funFact: `Un espace confiné ou un contenant ; aussi une forme de sport de combat. Saviez-vous que la boxe est l'un des sports les plus anciens, remontant à plus de 3 000 ans en Égypte ancienne ?` },
        { word: 'Bus', letters: ['B', 'U', 'S'], funFact: `Un véhicule de transport en commun. Saviez-vous que le premier bus à impériale a été mis en service à Londres en 1829 par George Shillibeer, offrant une alternative au transport en calèche ?`, },
        { word: 'But', letters: ['B', 'U', 'T'], funFact: `L'objectif à atteindre dans un jeu ou une compétition. Saviez-vous que le but le plus rapide jamais marqué lors d'un match de football professionnel a été inscrit par Hakan Şükür, joueur turc, en seulement 11 secondes ?` },
        { word: 'Cal', letters: ['C', 'A', 'L'], funFact: `Une unité de mesure utilisée pour exprimer la chaleur ou l'énergie. Saviez-vous que le mot "cal" est une abréviation de "calorie", une unité de mesure de l'énergie utilisée dans la nutrition et la physique ?` },
        { word: 'Cap', letters: ['C', 'A', 'P'], funFact: `Un vêtement couvrant la tête, souvent avec une visière ; aussi un point de repère sur la côte maritime. Saviez-vous que le Cap de Bonne-Espérance en Afrique du Sud était autrefois appelé le Cap des Tempêtes en raison de ses eaux dangereuses ?` },
        { word: 'Car', letters: ['C', 'A', 'R'], funFact: `Un véhicule de transport collectif ; aussi une conjonction causale. Saviez-vous que le premier service régulier d'autocar a été lancé en France en 1828 par Stanislas Baudry, reliant Nantes à Saint-Nazaire ?` },
        { word: 'Cas', letters: ['C', 'A', 'S'], funFact: `Une circonstance particulière ou une situation. Saviez-vous que "cas d'école" est une expression utilisée pour décrire une situation typique, souvent utilisée dans l'enseignement ?` },
        { word: 'Cru', letters: ['C', 'R', 'U'], funFact: `Non cuit, souvent utilisé pour décrire des aliments. Saviez-vous que certains restaurants proposent des plats à base de cuisine crue, tels que des sushis ou des carpaccios, pour les amateurs de saveurs authentiques ?` },
        { word: 'Dos', letters: ['D', 'O', 'S'], funFact: `La partie postérieure du corps humain ou d'un animal. Saviez-vous que les dauphins ont une nageoire dorsale distinctive qui les aide à stabiliser leur mouvement dans l'eau ?` },
        { word: 'Duo', letters: ['D', 'U', 'O'], funFact: `Un groupe de deux personnes travaillant ou se produisant ensemble. Saviez-vous que de nombreux duos musicaux célèbres, comme Simon & Garfunkel ou Daft Punk, ont connu un grand succès grâce à leur collaboration artistique ?` },
        { word: 'Eau', letters: ['E', 'A', 'U'], funFact: `Une substance liquide, transparente, inodore et insipide, essentielle à la vie. Saviez-vous que l'eau représente environ 60% du poids corporel d'un adulte humain ?` },
        { word: 'Fer', letters: ['F', 'E', 'R'], funFact: `Un métal commun, élément chimique de symbole Fe. Saviez-vous que le fer est l'un des éléments les plus abondants sur Terre, et qu'il constitue le noyau interne de notre planète ?` },
        { word: 'Feu', letters: ['F', 'E', 'U'], funFact: `La combustion rapide de matériaux, produisant chaleur, lumière et fumée. Saviez-vous que la découverte du feu par les premiers humains a été un tournant majeur dans l'évolution, permettant la cuisson des aliments et la protection contre les prédateurs ?` },
        { word: 'Fin', letters: ['F', 'I', 'N'], funFact: `La conclusion ou la terminaison de quelque chose. Saviez-vous que le mot "fin" est utilisé à la fois en français et en anglais pour indiquer la fin d'une histoire ou d'un film ?` },
        { word: 'Fou', letters: ['F', 'O', 'U'], funFact: `Une personne atteinte de folie ou un terme utilisé pour décrire quelque chose d'irrationnel. Saviez-vous que "fou" est aussi une pièce du jeu d'échecs, appelée "bishop" en anglais, se déplaçant en diagonale ?` },
        { word: 'Gaz', letters: ['G', 'A', 'Z'], funFact: `Un élément chimique présent dans l'atmosphère, tel que l'oxygène ou l'azote. Saviez-vous que le gaz naturel est principalement composé de méthane et est utilisé comme source d'énergie dans de nombreux foyers et industries à travers le monde ?` },
        { word: 'Gel', letters: ['G', 'E', 'L'], funFact: `L'état solide de l'eau à basse température. Ou un produit utilisé pour coiffer les cheveux et leur donner une apparence fixe. Saviez-vous que le gel pour les cheveux a été popularisé dans les années 1920 avec l'apparition du style de cheveux "à la gomina" ?` },
        { word: 'Hic', letters: ['H', 'I', 'C'], funFact: `Un léger problème ou une difficulté. Saviez-vous que l'expression "hic et nunc" est une locution latine signifiant "ici et maintenant", utilisée pour désigner le moment présent ?` },
        { word: 'Jam', letters: ['J', 'A', 'M'], funFact: `Séance musicale improvisée basée sur des standards de jazz à laquelle peuvent se joindre différents musiciens. Saviez-vous que les musiciens de jazz improvisent souvent en jouant ensemble dans une séance de "jam session" ?` },
        { word: 'Jet', letters: ['J', 'E', 'T'], funFact: `Un avion à réaction ou un flux rapide de liquide ou de gaz. Saviez-vous que le premier avion à réaction commercial, le De Havilland Comet, a effectué son vol inaugural en 1952 ?` },
        { word: 'Jeu', letters: ['J', 'E', 'U'], funFact: `Une activité récréative ou un divertissement. Saviez-vous que le jeu d'échecs est l'un des jeux de stratégie les plus anciens et les plus populaires au monde, pratiqué par des millions de personnes ?` },
        { word: 'Lac', letters: ['L', 'A', 'C'], funFact: `Une grande étendue d'eau entourée de terres. Saviez-vous que le lac Baïkal en Sibérie est le lac le plus profond du monde, avec une profondeur maximale de 1 642 mètres ?`, },
        { word: 'Les', letters: ['L', 'E', 'S'], funFact: `Article défini pluriel en français. Saviez-vous que "Les Misérables" est un célèbre roman de Victor Hugo, adapté en comédie musicale et en plusieurs films ?` },
        { word: 'Lis', letters: ['L', 'I', 'S'], funFact: `La deuxième personne du singulier de l'indicatif présent du verbe "lire". Saviez-vous que les Égyptiens de l'Antiquité utilisaient des hiéroglyphes pour écrire, gravant souvent des images de fleurs de lotus et de papyrus, qui symbolisaient la vie et la renaissance ?` },
        { word: 'Lit', letters: ['L', 'I', 'T'], funFact: `Un meuble utilisé pour dormir, généralement équipé d'un matelas et d'un sommier. Saviez-vous que le lit le plus grand du monde mesure 26,5 mètres de long et a été construit en 2011 lors du festival annuel de la bière de la ville de Farnham, en Angleterre ?` },
        { word: 'Lot', letters: ['L', 'O', 'T'], funFact: `Un ensemble d'objets ou une part de quelque chose. Saviez-vous que le Lot est également un département en France, connu pour ses paysages pittoresques et ses sites historiques ?` },
        { word: 'Lot', letters: ['L', 'O', 'T'], funFact: `Une part, un ensemble ou un destin. Saviez-vous que le terme "lot" est souvent utilisé dans les ventes aux enchères pour désigner un groupe d'articles vendus ensemble ?` },
        { word: 'Mer', letters: ['M', 'E', 'R'], funFact: `Une grande étendue d'eau salée qui couvre la majeure partie de la surface terrestre. Saviez-vous que la mer Morte, située entre Israël et la Jordanie, est l'un des plans d'eau les plus salés au monde, ce qui permet aux baigneurs de flotter facilement ?` },
        { word: 'Mie', letters: ['M', 'I', 'E'], funFact: `La partie centrale et molle du pain. Saviez-vous que la mie de pain est souvent considérée comme la meilleure partie, en particulier dans les pains frais et moelleux ?` },
        { word: 'Mot', letters: ['M', 'O', 'T'], funFact: `Un ensemble de lettres ou de sons qui a une signification particulière et qui est utilisé pour communiquer. Saviez-vous que le mot le plus long en anglais est "pneumonoultramicroscopicsilicovolcanoconiosis", qui fait référence à une maladie pulmonaire causée par l'inhalation de poussière de silice ?` },
        { word: 'Mur', letters: ['M', 'U', 'R'], funFact: `Une structure verticale qui divise ou soutient. Saviez-vous que le Mur de Berlin, construit en 1961 pour diviser l'Est et l'Ouest de la ville, est devenu un symbole de la guerre froide avant d'être démoli en 1989 ?` },
        { word: 'Net', letters: ['N', 'E', 'T'], funFact: `Propre et clair ; aussi une abréviation pour "Internet". Saviez-vous que le terme "net" peut également signifier "clair et précis", comme dans l'expression "réponse nette et précise" ?` },
        { word: 'Nid', letters: ['N', 'I', 'D'], funFact: `Le domicile d'un oiseau ou d'un insecte. Ou une petite quantité de quelque chose. Saviez-vous que l'expression "un nid de guêpes" est souvent utilisée pour décrire une situation délicate ou dangereuse qui peut devenir incontrôlable si elle est dérangée ?` },
        { word: 'Non', letters: ['N', 'O', 'N'], funFact: `Une négation ou un refus. Saviez-vous que le mot "non" est l'un des premiers mots que les enfants apprennent à utiliser pour exprimer leur désaccord ?` },
        { word: 'Nou', letters: ['N', 'O', 'U'], funFact: `Une forme de verbe "nouer". Saviez-vous que les nœuds marins, tels que le nœud de chaise ou le nœud de cabestan, sont utilisés depuis des siècles par les marins pour attacher des cordes et sécuriser des objets à bord des navires ?` },
        { word: 'Oie', letters: ['O', 'I', 'E'], funFact: `Un grand oiseau aquatique, souvent domestiqué pour sa viande et ses plumes. Saviez-vous que les oies sauvages migrent sur de longues distances, souvent en formations en V pour économiser de l'énergie ?` },
        { word: 'Oui', letters: ['O', 'U', 'I'], funFact: `Une affirmation ou une approbation. Saviez-vous que dire "oui" à de nouvelles expériences peut ouvrir des portes et vous permettre de découvrir des aspects de la vie que vous n'auriez jamais imaginés ? Dire "oui" peut être bénéfique pour la santé mentale, car cela encourage une attitude positive et ouvre de nouvelles possibilités ?` },
        { word: 'Pas', letters: ['P', 'A', 'S'], funFact: `Un mouvement d'un pied devant l'autre. Saviez-vous que le pas de danse "moonwalk" popularisé par Michael Jackson est devenu une icône de la culture pop dans les années 1980 ?` },
        { word: 'Pic', letters: ['P', 'I', 'C'], funFact: `Une pointe ou un sommet ; aussi un outil utilisé pour creuser. Saviez-vous que le Mont Everest est le plus haut pic du monde, culminant à 8 848 mètres au-dessus du niveau de la mer ?` },
        { word: 'Pif', letters: ['P', 'I', 'F'], funFact: `Le nez. Saviez-vous que l'expression "au pif" signifie faire quelque chose au hasard ou sans réfléchir ?` },
        { word: 'Pou', letters: ['P', 'O', 'U'], funFact: `Un insecte parasite qui infeste souvent les cheveux ou la peau des humains et des animaux. Saviez-vous que les poux ont existé depuis des millions d'années et ont été retrouvés dans des momies égyptiennes datant de 3 000 ans ?` },
        { word: 'Put', letters: ['P', 'U', 'T'], funFact: `Le participe passé du verbe "put". Saviez-vous que "put" est souvent utilisé dans le langage financier pour décrire une option de vente, permettant à un investisseur de vendre un actif à un prix spécifié à une date ultérieure ?` },
        { word: 'Rat', letters: ['R', 'A', 'T'], funFact: `Un petit rongeur omnivore, souvent considéré comme nuisible. Saviez-vous que les rats sont des animaux très intelligents et sociaux, capables de résoudre des problèmes complexes et de faire preuve de compassion envers leurs congénères ?` },
        { word: 'Riz', letters: ['R', 'I', 'Z'], funFact: `Une céréale cultivée principalement dans les régions tropicales et subtropicales. Saviez-vous que le riz est l'aliment de base pour plus de la moitié de la population mondiale, avec plus de 40 000 variétés différentes cultivées à travers le monde ?` },
        { word: 'Roi', letters: ['R', 'O', 'I'], funFact: `Un souverain masculin d'un royaume. Saviez-vous que Louis XIV de France, connu sous le nom de Roi Soleil, a régné pendant 72 ans, ce qui en fait l'un des plus longs règnes de l'histoire européenne ?` },
        { word: 'Rue', letters: ['R', 'U', 'E'], funFact: `Une voie publique bordée de bâtiments dans une ville ou un village. Saviez-vous que la rue Lombard à San Francisco est célèbre pour être la rue la plus sinueuse du monde ? La rue la plus étroite du monde se trouve à Reutlingen, en Allemagne, et mesure seulement 31 centimètres de large à son point le plus étroit.`, },
        { word: 'Sac', letters: ['S', 'A', 'C'], funFact: `Un contenant souple utilisé pour transporter des objets. Saviez-vous que le sac à main le plus cher au monde est le sac "Mouawad 1001 Nuits Diamond Purse", incrusté de 4 517 diamants et valant plus de 3,8 millions de dollars ? Le sac est également une ancienne unité de mesure utilisée pour peser des marchandises telles que le grain, le sucre ou le café dans les commerces. Le mot "sac" est également utilisé dans le jargon militaire pour désigner une mission difficile ou dangereuse.` },
        { word: 'Sec', letters: ['S', 'E', 'C'], funFact: `Qui ne contient pas d'humidité. Saviez-vous que le désert d'Atacama au Chili est l'endroit le plus sec sur Terre, avec des endroits n'ayant jamais enregistré de précipitations ?` },
        { word: 'Sel', letters: ['S', 'E', 'L'], funFact: `Un composé chimique couramment utilisé pour assaisonner les aliments. Saviez-vous que le mot "salarium" en latin, qui signifie "salaire", est dérivé de "sel", car dans l'Antiquité les soldats romains étaient souvent payés en sel ?` },
        { word: 'Sol', letters: ['S', 'O', 'L'], funFact: `La surface de la Terre. Saviez-vous que le sol contient des milliards de micro-organismes par mètre carré, jouant un rôle crucial dans la décomposition des matières organiques et le cycle des nutriments ?` },
        { word: 'Son', letters: ['S', 'O', 'N'], funFact: `Les vibrations perçues par l'oreille comme un son. Saviez-vous que les animaux utilisent souvent le son pour communiquer entre eux, que ce soit pour trouver un partenaire, avertir d'un danger ou marquer leur territoire ?` },
        { word: 'Tas', letters: ['T', 'A', 'S'], funFact: `Une pile ou un amas d'objets. Saviez-vous que l'expression "faire un tas" peut également signifier économiser de l'argent ou accumuler des ressources ?` },
        { word: 'Thé', letters: ['T', 'H', 'É'], funFact: `Une boisson chaude ou froide infusée à partir des feuilles de thé. Saviez-vous que le thé est la deuxième boisson la plus consommée au monde après l'eau, et qu'il existe des centaines de variétés de thé avec des saveurs et des arômes uniques ?` },
        { word: 'Vin', letters: ['V', 'I', 'N'], funFact: `Une boisson alcoolisée produite à partir de raisins fermentés. Saviez-vous que le vin le plus ancien jamais découvert a été trouvé dans une cave en Arménie et date d'environ 6 000 ans ?`, },
        { word: 'Vue', letters: ['V', 'U', 'E'], funFact: `La capacité de percevoir l'environnement par les yeux. Saviez-vous que les aigles ont une vision environ 4 à 8 fois plus nette que celle des humains, leur permettant de repérer des proies à des kilomètres de distance ?` },
        { word: 'Zip', letters: ['Z', 'I', 'P'], funFact: `Un dispositif de fermeture à glissière ; aussi un fichier compressé en informatique. Saviez-vous que la fermeture éclair a été inventée par Whitcomb Judson en 1893, mais n'a gagné en popularité qu'avec les améliorations apportées par Gideon Sundback en 1913 ?` },
    ],
    2: [
        { word: 'Moto', letters: ['M', 'O', 'T', 'O'], funFact: `Un véhicule à deux roues propulsé par un moteur. Saviez-vous que la première moto à essence a été inventée par l'ingénieur allemand Gottlieb Daimler en 1885? Cette moto, surnommée la "Reitwagen" (ou "machine à chevaucher"), était équipée d'un moteur à combustion interne.`, },
        { word: 'Lune', letters: ['L', 'U', 'N', 'E'], funFact: `Le satellite naturel de la Terre, en orbite autour de celle-ci. Saviez-vous que la Lune s'éloigne progressivement de la Terre à raison d'environ 3,8 centimètres par an? Cela signifie qu'il y a des millions d'années, la Lune était beaucoup plus proche de notre planète et semblait donc beaucoup plus grande dans le ciel.`, },
        { word: 'Vent', letters: ['V', 'E', 'N', 'T'], funFact: `Le déplacement de l'air d'une région à une autre, généralement causé par des différences de pression atmosphérique. Saviez-vous que le vent le plus fort jamais enregistré sur Terre a soufflé à 408 km/h (253 mph) le 10 avril 1996 sur le mont Washington, dans le New Hampshire, aux États-Unis?`, },
        { word: 'Port', letters: ['P', 'O', 'R', 'T'], funFact: `Un endroit sur la côte où les navires peuvent accoster pour charger et décharger des marchandises ou des passagers. Saviez-vous que le port de Shanghai en Chine est actuellement le plus grand port du monde en termes de volume de marchandises? Il a dépassé le port de Singapour en 2010.`, },
        { word: 'Rire', letters: ['R', 'I', 'R', 'E'], funFact: `Une expression joyeuse du visage et des sons produits par la gorge souvent en réponse à quelque chose de comique ou de plaisant. Saviez-vous que selon les recherches, le rire peut réduire le stress, améliorer la fonction cardiovasculaire et renforcer le système immunitaire? Il est souvent considéré comme un élément clé du bien-être émotionnel et physique.`, },
        { word: 'Chut', letters: ['C', 'H', 'U', 'T'], funFact: `Un son utilisé pour demander le silence ou pour exprimer le secret. Saviez-vous que le bruit de fond le plus silencieux jamais enregistré par les scientifiques est d'environ 10^-36 décibels, soit un million de milliards de fois plus faible que le niveau d'audition humain normal ?`, },
        { word: 'Ciel', letters: ['C', 'I', 'E', 'L'], funFact: `L'atmosphère ou l'espace visible au dessus de la Terre. Saviez-vous que le ciel apparaît bleu en raison de la dispersion de la lumière par les molécules de l'atmosphère, un phénomène appelé diffusion de Rayleigh ?`, }
    ],
    3: [
        { word: 'Chien', letters: ['C', 'H', 'I', 'E', 'N'], funFact: `Un mammifère domestique courant, souvent gardien ou compagnon de l'homme. Saviez-vous que le chien est souvent considéré comme le meilleur ami de l'homme en raison de sa loyauté et de sa nature sociable? Cette relation entre l'homme et le chien remonte à des milliers d'années.`, },
        { word: 'Pomme', letters: ['P', 'O', 'M', 'M', 'E'], funFact: `Le fruit du pommier, généralement rouge, vert ou jaune, avec une peau lisse et une chair croquante et juteuse. Saviez-vous que la pomme est l'un des fruits les plus consommés au monde? Il existe des milliers de variétés de pommes, chacune avec son propre goût, texture et couleur.`, },
        { word: 'Livre', letters: ['L', 'I', 'V', 'R', 'E'], funFact: `Un ensemble de feuilles de papier, parchemin ou autres matériaux reliées ensemble, souvent imprimées ou manuscrites, et formant un volume. Saviez-vous que le livre imprimé a été inventé en Chine par Bi Sheng vers 1040? Cela a révolutionné la diffusion des connaissances et des idées à travers le monde.`, },
        { word: 'Sucre', letters: ['S', 'U', 'C', 'R', 'E'], funFact: `Un glucide cristallin doux, soluble dans l'eau, extrait de diverses plantes, principalement de la canne à sucre et de la betterave à sucre, et utilisé dans la préparation des aliments et des boissons. Saviez-vous que le sucre était autrefois considéré comme un produit de luxe réservé aux riches? Il était si précieux qu'il était parfois enfermé dans des coffres-forts.`, },
        { word: 'Radio', letters: ['R', 'A', 'D', 'I', 'O'], funFact: `Un moyen de communication utilisant des ondes électromagnétiques pour transmettre des informations, généralement sous forme de sons. Saviez-vous que la première transmission radio a été réalisée par Guglielmo Marconi en 1895? Il a réussi à envoyer un signal radio sur une distance d'environ 1,5 kilomètre (environ 1 mile).` },
        { word: 'Atome', letters: ['A', 'T', 'O', 'M', 'E'], funFact: `Un atome est la plus petite unité constitutive de la matière, composée de protons, de neutrons et d'électrons. Saviez-vous que la théorie atomique a été développée au début du 19e siècle par des scientifiques comme John Dalton, mais c'est en 1897 que J.J. Thomson a découvert l'électron, prouvant que les atomes n'étaient pas indivisibles ? La compréhension des atomes a conduit à des avancées majeures en chimie et en physique, notamment dans le développement de l'énergie nucléaire. Et vous, que vous évoque ce mot ?`, },
        { word: 'Echec', letters: ['E', 'C', 'H', 'E', 'C'], funFact: `L'échec désigne l'absence de réussite ou le fait de ne pas atteindre un objectif. Saviez-vous que de nombreux inventeurs célèbres, comme Thomas Edison, ont connu des échecs avant de réussir ? Edison a déclaré : "Je n'ai pas échoué. J'ai juste trouvé 10 000 façons qui ne fonctionnent pas" en parlant de ses expériences avec l'ampoule électrique. Cet état d'esprit sur l'échec a inspiré des générations d'entrepreneurs et d'innovateurs à persévérer. Et vous, que vous évoque ce mot ?`, },
        { word: 'Lampe', letters: ['L', 'A', 'M', 'P', 'E'], funFact: `Une lampe est un dispositif utilisé pour éclairer un espace. Saviez-vous que la première lampe électrique a été inventée par Thomas Edison en 1879, mais l'idée de l'éclairage remonte à la préhistoire avec l'utilisation de feux ouverts ? Aujourd'hui, des innovations comme les lampes LED offrent une efficacité énergétique bien supérieure, consommant jusqu'à 80% moins d'énergie que les ampoules à incandescence traditionnelles. Et vous, que vous évoque ce mot ?`, },
        { word: 'Avion', letters: ['A', 'V', 'I', 'O', 'N'], funFact: `Un avion est un véhicule aérien conçu pour transporter des passagers ou des marchandises. Saviez-vous que les frères Wright ont réalisé le premier vol motorisé contrôlé en 1903 avec leur avion, le Wright Flyer, qui a parcouru 36,5 mètres en 12 secondes ? Depuis lors, l'aviation a connu des avancées spectaculaires, transformant le transport international et réduisant le temps de voyage autour du monde. En 2019, environ 4,5 milliards de passagers ont pris l'avion, montrant à quel point ce mode de transport est devenu essentiel dans notre vie moderne. Et vous, que vous évoque ce mot ?`, }
    ],
    4: [
        { word: 'Banane', letters: ['B', 'A', 'N', 'A', 'N', 'E'], funFact: `Le fruit du bananier, avec une peau jaune et une chair douce et crémeuse. Saviez-vous que la banane est le quatrième produit alimentaire le plus important au monde après le riz, le blé et le lait? Elle est cultivée dans plus de 130 pays.`, },
        { word: 'Diable', letters: ['D', 'I', 'A', 'B', 'L', 'E'], funFact: `Dans de nombreuses traditions religieuses et mythologiques, une figure maléfique associée au mal ou au diable. Saviez-vous que dans la mythologie grecque, le dieu Pan, souvent représenté avec des cornes et des pieds de bouc, est parfois considéré comme une incarnation du diable dans la tradition chrétienne ?`, },
        { word: 'Soleil', letters: ['S', 'O', 'L', 'E', 'I', 'L'], funFact: `L'étoile au centre de notre système solaire, autour de laquelle la Terre et d'autres planètes orbitent. Saviez-vous que le Soleil représente environ 99,8% de la masse totale de notre système solaire? C'est une énorme sphère de gaz chaud qui produit de la lumière et de la chaleur.`, },
        { word: 'Orange', letters: ['O', 'R', 'A', 'N', 'G', 'E'], funFact: `Le fruit de l'oranger, avec une peau épaisse et rugueuse et une chair juteuse et sucrée. Saviez-vous que le nom de la couleur "orange" provient du nom du fruit? Auparavant, la couleur était simplement décrite comme une nuance de rouge ou de jaune.`, },
        { word: 'Écoles', letters: ['É', 'C', 'O', 'L', 'E', 'S'], funFact: `Une institution où les enfants et les jeunes adultes reçoivent une éducation formelle.Saviez-vous que la plus ancienne école encore en fonctionnement est l'école King's School à Canterbury, en Angleterre? Fondée en 597, cette école a une histoire qui remonte à plus de 1400 ans.` },
        { word: 'Hockey', letters: ['H', 'O', 'C', 'K', 'E', 'Y'], funFact: `Le hockey est un sport d'équipe qui se joue avec une crosse pour frapper une balle ou un disque (puck) et marquer des buts dans le but de l'adversaire. Saviez-vous que le hockey sur glace est né au Canada dans les années 1800 et est devenu un sport professionnel dans la Ligue nationale de hockey (LNH) en 1917 ? La popularité de ce sport a conduit à des événements emblématiques comme les Jeux Olympiques, où le Canada et la Russie se disputent souvent les médailles d'or. Le hockey sur glace est également le sport national du Canada, reflétant son importance culturelle. Et vous, que vous évoque ce mot ?`, }
    ],
    5: [
        { word: 'Bonheur', letters: ['B', 'O', 'N', 'H', 'E', 'U', 'R'], funFact: `Un état de bien-être et de satisfaction personnelle. Saviez-vous que selon des études, le bonheur est souvent associé à des interactions sociales positives, à des expériences enrichissantes et à un sentiment de réalisation personnelle plutôt qu'à des possessions matérielles?`, },
        { word: 'Chanson', letters: ['C', 'H', 'A', 'N', 'S', 'O', 'N'], funFact: `Une composition musicale généralement chantée par un ou plusieurs chanteurs. Saviez-vous que la chanson la plus vendue de tous les temps est "White Christmas" de Bing Crosby? Sortie en 1942, elle a vendu plus de 50 millions d'exemplaires dans le monde entier.`, },
        { word: 'Nuageux', letters: ['N', 'U', 'A', 'G', 'E', 'U', 'X'], funFact: `Un état de temps où le ciel est couvert de nuages. Saviez-vous que les nuages ​​sont formés de minuscules gouttelettes d'eau ou de cristaux de glace en suspension dans l'atmosphère? Leur forme et leur type dépendent de facteurs tels que l'humidité, la température et l'altitude.`, },
        { word: 'Biscuit', letters: ['B', 'I', 'S', 'C', 'U', 'I', 'T'], funFact: `Un petit gâteau sec et friable, souvent sucré et parfois aromatisé. Saviez-vous que le mot "biscuit" vient du latin "bis coctus", qui signifie "cuit deux fois"? Traditionnellement, les biscuits étaient cuits deux fois pour les rendre plus croustillants et durables.`, },
        { word: 'Journal', letters: ['J', 'O', 'U', 'R', 'N', 'A', 'L'], funFact: `Une publication périodique contenant des nouvelles, des commentaires, des articles et d'autres informations. Saviez-vous que le premier journal imprimé régulièrement était la ""Gazette"" publiée en Angleterre en 1665? Depuis lors, les journaux sont devenus une source majeure d'information dans le monde entier.`, }
    ],
    6: [
        { word: 'Écologie', letters: ['É', 'C', 'O', 'L', 'O', 'G', 'I', 'E'], funFact: `La branche de la biologie qui étudie les interactions entre les organismes et leur environnement. Saviez-vous que le terme "écologie" a été introduit en 1866 par le biologiste allemand Ernst Haeckel? Il l'a dérivé du grec "oikos", qui signifie "maison" ou "foyer", et "logie", qui signifie "étude de".`, },
        { word: 'Coquille', letters: ['C', 'O', 'Q', 'U', 'I', 'L', 'L', 'E'], funFact: `La coquille extérieure d'un œuf ou le squelette externe d'un mollusque. Saviez-vous que les coquilles d'œufs sont principalement composées de carbonate de calcium? Elles sont relativement solides mais peuvent être fragiles en fonction de l'espèce d'oiseau.`, },
        { word: 'Paysanne', letters: ['P', 'A', 'Y', 'S', 'A', 'N', 'N', 'E'], funFact: `Une femme travaillant dans l'agriculture, généralement dans un cadre rural. Saviez-vous que les paysans et paysannes ont joué un rôle essentiel dans l'histoire de l'agriculture? Leurs connaissances traditionnelles ont souvent été transmises de génération en génération.`, },
        { word: 'Chausson', letters: ['C', 'H', 'A', 'U', 'S', 'S', 'O', 'N'], funFact: `Un petit soulier ou une chaussure légère, souvent portée à l'intérieur. Saviez-vous que les chaussons sont parfois appelés "pantoufles"? Ce terme provient du français "pantoufle", qui désignait à l'origine une chaussure souple sans talon portée à l'intérieur.`, },
        { word: 'Amalgame', letters: ['A', 'M', 'A', 'L', 'G', 'A', 'M', 'E'], funFact: `Une combinaison ou un mélange de différentes choses. Saviez-vous que l'amalgame dentaire, utilisé pour remplir les cavités, est composé principalement de mercure et d'autres métaux comme l'argent, le zinc et le cuivre?` },
        { word: 'Peinture', letters: ['P', 'E', 'I', 'N', 'T', 'U', 'R', 'E'], funFact: `La peinture est une forme d'art visuel qui consiste à appliquer des pigments sur une surface pour créer des images ou des motifs. Saviez-vous que la peinture existe depuis la préhistoire, avec les premières œuvres trouvées dans des grottes, comme celles de Lascaux en France, datant d'environ 17 000 ans ? Au cours des siècles, la peinture a évolué, donnant naissance à des mouvements comme le cubisme et l'impressionnisme, chacun ayant influencé l'art et la culture de manière significative. Aujourd'hui, des artistes contemporains continuent d'explorer de nouvelles techniques et matériaux, faisant de la peinture un médium toujours vivant. Et vous, que vous évoque ce mot ?`, },
        { word: 'Histoire', letters: ['H', 'I', 'S', 'T', 'O', 'I', 'R', 'E'], funFact: `L'histoire est l'étude des événements passés, en particulier ceux liés à l'humanité. Saviez-vous que l'histoire écrite commence vers 3 000 av. J.-C. avec l'invention de l'écriture en Mésopotamie ? Cette période a marqué le début de la documentation des événements, permettant aux générations futures de comprendre les sociétés anciennes. L'étude de l'histoire est essentielle pour comprendre les dynamiques sociales, politiques et économiques actuelles, et les leçons tirées du passé continuent d'influencer notre monde moderne. Et vous, que vous évoque ce mot ?`, },
        { word: 'Montagne', letters: ['M', 'O', 'N', 'T', 'A', 'G', 'N', 'E'], funFact: `Une montagne est une élévation naturelle du terrain, souvent caractérisée par une altitude significativement plus élevée que celle des terrains environnants. Saviez-vous que l'Everest, la plus haute montagne du monde, culmine à 8 848 mètres d'altitude ? Découverte par l'Occident en 1852, elle a depuis attiré des alpinistes du monde entier, bien que l'ascension soit extrêmement périlleuse. Les montagnes jouent un rôle crucial dans l'écosystème, influençant le climat et abritant une biodiversité unique. Et vous, que vous évoque ce mot ?`, }
    ],
    7: [
        { word: 'Parapluie', letters: ['P', 'A', 'R', 'A', 'P', 'L', 'U', 'I', 'E'], funFact: `Un dispositif portatif utilisé pour se protéger de la pluie ou du soleil, composé d'un auvent maintenu par des baleines ou des tiges pliables fixées à un manche. Saviez-vous que les premiers parapluies étaient utilisés il y a des milliers d'années en Égypte, en Chine et dans d'autres régions du monde? Ils étaient souvent fabriqués à partir de feuilles de palmier ou de papier huilé.`, },
        { word: 'Téléphone', letters: ['T', 'É', 'L', 'É', 'P', 'H', 'O', 'N', 'E'], funFact: `Un appareil de communication électronique permettant de transmettre la voix ou d'autres données à distance. Saviez-vous que le premier brevet pour un appareil téléphonique a été déposé par l'inventeur américain Alexander Graham Bell en 1876? Cela a marqué le début de l'ère des télécommunications.`, },
        { word: 'Universel', letters: ['U', 'N', 'I', 'V', 'E', 'R', 'S', 'E', 'L'], funFact: `Qui concerne ou s'applique à tout le monde ou à tout ce qui existe. Saviez-vous que le concept d'universalité a été discuté par de nombreux philosophes au fil des siècles? Des penseurs comme Platon et Kant ont exploré l'idée d'idées ou de principes universels.`, },
        { word: 'Tradition', letters: ['T', 'R', 'A', 'D', 'I', 'T', 'I', 'O', 'N'], funFact: `Une pratique, une croyance ou un événement transmis de génération en génération. Saviez-vous que la tradition du repas de Noël remonte à l'Antiquité romaine? Les Saturnales, une fête en l'honneur du dieu Saturne, étaient célébrées avec des festins et des échanges de cadeaux, ce qui a contribué à l'évolution des célébrations de Noël telles que nous les connaissons aujourd'hui.`, },
        { word: 'Inattendu', letters: ['I', 'N', 'A', 'T', 'T', 'E', 'N', 'D', 'U'], funFact: `Qui survient de manière imprévue ou surprenante. Saviez-vous que la découverte du radium par Marie Curie en 1898 a été totalement inattendue? Elle a remarqué que des sels d'uranium émettaient une lumière fluorescente, un phénomène inconnu jusque-là, ce qui a finalement conduit à la découverte du radium.`, },
        { word: 'Éducation', letters: ['É', 'D', 'U', 'C', 'A', 'T', 'I', 'O', 'N'], funFact: `Éducation désigne le processus d'enseignement et d'apprentissage. Saviez-vous que l'éducation a évolué au fil des siècles, passant de l'enseignement informel à des systèmes éducatifs modernes ? En 1870, la loi sur l'instruction obligatoire en France a été adoptée, garantissant l'accès à l'éducation pour tous les enfants. Et vous, que vous évoque ce mot ?`, },
        { word: 'Déférence', letters: ['D', 'É', 'F', 'É', 'R', 'E', 'N', 'C', 'E'], funFact: `La déférence est le respect ou la considération que l'on accorde à quelqu'un. Saviez-vous que la déférence a été valorisée dans de nombreuses cultures, souvent comme un moyen de maintenir l'harmonie sociale ? Dans les sociétés asiatiques, par exemple, le respect des aînés est un principe fondamental. Et vous, que vous évoque ce mot ?`, }
    ],
    8: [
        { word: 'Architecte', letters: ['A', 'R', 'C', 'H', 'I', 'T', 'E', 'C', 'T', 'E'], funFact: `Une personne qualifiée pour concevoir des bâtiments et superviser leur construction. Saviez-vous que l'un des architectes les plus célèbres de tous les temps était Léonard de Vinci? Bien qu'il soit plus connu pour ses travaux en peinture et en science, de Vinci a également conçu des plans pour de nombreux bâtiments et structures innovantes.`, },
        { word: 'Résolution', letters: ['R', 'É', 'S', 'O', 'L', 'U', 'T', 'I', 'O', 'N'], funFact: `Une décision formelle prise lors d'une réunion ou d'une assemblée. Saviez-vous que la tradition des résolutions du Nouvel An remonte à l'ancienne Babylone, il y a plus de 4000 ans? Les Babyloniens célébraient le nouvel an en promettant de rembourser leurs dettes et de rendre les objets empruntés.`, },
        { word: 'Électrique', letters: ['É', 'L', 'E', 'C', 'T', 'R', 'I', 'Q', 'U', 'E'], funFact: `Relatif à l'électricité ou fonctionnant à l'électricité. Saviez-vous que la première utilisation commerciale de l'électricité remonte à 1879 lorsque Thomas Edison a mis en service la première centrale électrique à New York? Cela a marqué le début de l'ère de l'électricité à grande échelle.`, },
        { word: 'Ressources', letters: ['R', 'E', 'S', 'S', 'O', 'U', 'R', 'C', 'E', 'S'], funFact: `Les moyens disponibles pour être utilisés ou exploités dans le but de produire quelque chose. Saviez-vous que certaines ressources naturelles, comme le pétrole et le charbon, ont été formées il y a des millions d'années à partir de la matière organique en décomposition? Leur utilisation massive a des implications majeures pour l'environnement et l'économie mondiale.`, },
        { word: 'Atmosphère', letters: ['A', 'T', 'M', 'O', 'S', 'P', 'H', 'È', 'R', 'E'], funFact: `La couche de gaz entourant la Terre ou toute autre planète. Saviez-vous que l'atmosphère de la Terre est composée principalement de azote (78%) et de dioxygène (21%) avec des traces d'autres gaz? Cette composition est essentielle à la vie telle que nous la connaissons.`, },
        { word: 'Partenaire', letters: ['P', 'A', 'R', 'T', 'E', 'N', 'A', 'I', 'R', 'E'], funFact: `Personne qui partage une relation ou une activité avec quelqu'un d'autre. Saviez-vous que le terme "partenaire" a évolué pour inclure des concepts modernes de collaboration et d'égalité, notamment dans les relations amoureuses ? Dans le passé, le mariage était souvent perçu comme une institution patriarcale, mais aujourd'hui, de nombreux couples recherchent un partenariat égalitaire, favorisant le respect et le soutien mutuel.` },
        { word: 'Abandonner', letters: ['A', 'B', 'A', 'N', 'D', 'O', 'N', 'N', 'E', 'R'], funFact: `Cesser de soutenir ou de maintenir quelque chose. Saviez-vous que l'abandon d'une tradition, comme le fait de ne plus fêter certaines coutumes, peut avoir des effets durables sur la culture d'une communauté ? Les traditions façonnent l'identité d'un groupe, et leur disparition peut conduire à un affaiblissement des liens sociaux et culturels. Par exemple, certaines danses folkloriques ont disparu parce que les générations plus jeunes ne les pratiquent plus, ce qui a conduit à des efforts de revitalisation pour préserver ces traditions.` },
        { word: 'Admirateur', letters: ['A', 'D', 'M', 'I', 'R', 'A', 'T', 'E', 'U', 'R'], funFact: `Personne qui éprouve de l'admiration pour quelqu'un ou quelque chose. Saviez-vous que des figures comme Léonard de Vinci et Michel-Ange avaient de nombreux admirateurs qui ont contribué à leur renommée ? Leur art était non seulement admiré par les mécènes de leur temps, mais il a également laissé un héritage durable. Par exemple, la fresque de la Chapelle Sixtine, réalisée par Michel-Ange, attire des millions de visiteurs chaque année, témoignant de l'admiration et de l'inspiration qu'elle continue de susciter.` },
        { word: 'Argumenter', letters: ['A', 'R', 'G', 'U', 'M', 'E', 'N', 'T', 'E', 'R'], funFact: `Présenter des raisons en faveur ou en défaveur de quelque chose. Saviez-vous que l'art de l'argumentation est enseigné dans de nombreuses écoles de droit et de débats ? Les avocats et les orateurs politiques utilisent des techniques d'argumentation pour persuader et convaincre, faisant de cette compétence un outil puissant dans les discussions publiques et privées.` },
        { word: 'Assistance', letters: ['A', 'S', 'S', 'I', 'S', 'T', 'A', 'N', 'C', 'E'], funFact: `Action d'aider ou d'assister quelqu'un. Saviez-vous que l'assistance humanitaire a été mise en place pour aider les populations touchées par des crises, comme des guerres ou des catastrophes naturelles ? Des organisations comme la Croix-Rouge travaillent sans relâche pour fournir des secours d'urgence, des soins médicaux et un soutien psychologique aux personnes dans le besoin.` },
        { word: 'Bénéficier', letters: ['B', 'É', 'N', 'É', 'F', 'I', 'C', 'I', 'E', 'R'], funFact: `Tirer profit ou avantage de quelque chose. Saviez-vous que le concept de "bénéfice" est central dans le monde des affaires et de l'économie ? Les entreprises cherchent à maximiser leurs bénéfices pour assurer leur croissance et leur viabilité, tandis que les individus peuvent bénéficier d'initiatives sociales qui améliorent leur qualité de vie.` },
        { word: 'Conception', letters: ['C', 'O', 'N', 'C', 'E', 'P', 'T', 'I', 'O', 'N'], funFact: `Action de concevoir ou de planifier quelque chose. Saviez-vous que la conception de produits implique souvent des processus de recherche approfondie pour comprendre les besoins des utilisateurs ? Les designers et les ingénieurs travaillent ensemble pour créer des produits qui allient fonctionnalité et esthétique, un équilibre crucial pour le succès commercial.` },
        { word: 'Décoration', letters: ['D', 'É', 'C', 'O', 'R', 'A', 'T', 'I', 'O', 'N'], funFact: `Action de décorer ou d'embellir quelque chose. Saviez-vous que la décoration intérieure a des racines historiques profondes et qu'elle a évolué au fil des siècles ? Dans l'Antiquité, les maisons étaient décorées avec des fresques et des mosaïques, tandis que le style victorien a mis l'accent sur des détails ornés, reflétant la richesse et le statut social.` },
        { word: 'Délégation', letters: ['D', 'É', 'L', 'É', 'G', 'A', 'T', 'I', 'O', 'N'], funFact: `Action de confier une tâche ou une responsabilité à quelqu'un d'autre. Saviez-vous que la délégation de tâches est une compétence essentielle pour les leaders ? Une bonne délégation permet d'optimiser les performances et de renforcer la confiance au sein des équipes. Les managers qui maîtrisent cette compétence peuvent se concentrer sur des tâches stratégiques, tout en développant les compétences de leurs subordonnés.` },
        { word: 'Délinquant', letters: ['D', 'É', 'L', 'I', 'N', 'Q', 'U', 'A', 'N', 'T'], funFact: `Personne qui commet des actes illégaux ou antisociaux. Saviez-vous que les programmes de réhabilitation pour les délinquants, qui visent à réinsérer ces personnes dans la société, ont montré des taux de succès remarquables ? Des études ont montré que l'éducation et la formation professionnelle peuvent réduire considérablement le taux de récidive, permettant à des milliers de délinquants de reconstruire leur vie. Histoire` },
        { word: 'Éducatrice', letters: ['É', 'D', 'U', 'C', 'A', 'T', 'R', 'I', 'C', 'E'], funFact: `Personne qui enseigne ou forme des élèves. Saviez-vous que les éducatrices jouent un rôle clé dans le développement des enfants, en influençant leurs compétences sociales et académiques ? Dans de nombreuses cultures, l'éducation précoce est considérée comme essentielle pour préparer les enfants à la vie, soulignant l'importance des éducateurs dans la société.` },
        { word: 'Engagement', letters: ['E', 'N', 'G', 'A', 'G', 'E', 'M', 'E', 'N', 'T'], funFact: `Promesse de soutenir ou de participer activement à quelque chose. Saviez-vous que l'engagement civique, comme voter ou participer à des manifestations, a des racines historiques profondes ? Des mouvements tels que les droits civiques ont mobilisé des milliers de personnes pour défendre leurs droits, illustrant l'importance de l'engagement pour le changement social et politique.` },
        { word: 'Esthétique', letters: ['E', 'S', 'T', 'H', 'É', 'T', 'I', 'Q', 'U', 'E'], funFact: `Relatif à la beauté ou au goût artistique. Saviez-vous que l'esthétique a été étudiée depuis l'Antiquité ? Des philosophes comme Platon et Aristote ont exploré les concepts de beauté, influençant l'art et la culture pendant des siècles. Aujourd'hui, l'esthétique continue de façonner les tendances dans le design, l'architecture et les arts visuels.` },
        { word: 'Estimation', letters: ['E', 'S', 'T', 'I', 'M', 'A', 'T', 'I', 'O', 'N'], funFact: `Évaluation approximative d'une quantité ou d'une valeur. Saviez-vous que les estimations sont souvent utilisées dans les domaines de la finance et de la construction pour prévoir les coûts ? Les estimations précises peuvent faire la différence entre le succès et l'échec d'un projet, car elles aident à planifier les ressources et à établir des budgets réalistes.` },
        { word: 'Excellence', letters: ['E', 'X', 'C', 'E', 'L', 'L', 'E', 'N', 'C', 'E'], funFact: `État d'être exceptionnel ou de très haute qualité. Saviez-vous que des prix comme le Prix Nobel sont décernés en reconnaissance de l'excellence dans divers domaines tels que la paix, la littérature et la science ? Ces prix célèbrent les réalisations qui ont un impact positif sur l'humanité et inspirent d'autres à viser l'excellence dans leur travail.` },
        { word: 'Expédition', letters: ['E', 'X', 'P', 'É', 'D', 'I', 'T', 'I', 'O', 'N'], funFact: `Voyage organisé pour un but spécifique, souvent de recherche ou d'exploration. Saviez-vous que l'expédition de Lewis et Clark aux États-Unis, qui a commencé en 1804, a duré près de trois ans et a été organisée pour explorer le territoire récemment acquis de la Louisiane ? Cette expédition a non seulement fourni des informations précieuses sur la géographie, la faune et la flore, mais elle a également établi des relations avec les peuples autochtones et ouvert la voie à l'expansion vers l'ouest.` },
        { word: 'Florissant', letters: ['F', 'L', 'O', 'R', 'I', 'S', 'S', 'A', 'N', 'T'], funFact: `Qui prospère ou connaît un succès. Saviez-vous que certaines régions ont connu des périodes florissantes grâce à des ressources naturelles, comme le pétrole ou l'or ? Par exemple, la ruée vers l'or en Californie au XIXe siècle a attiré des milliers de personnes, transformant l'économie et la démographie de la région.` },
        { word: 'Harmoniser', letters: ['H', 'A', 'R', 'M', 'O', 'N', 'I', 'S', 'E', 'R'], funFact: `Mettre en accord des éléments pour créer une harmonie. Saviez-vous que l'harmonisation en musique, qui consiste à combiner des sons pour créer une mélodie agréable, a des racines anciennes dans des cultures comme la Grèce antique ? La musique a toujours joué un rôle crucial dans la vie sociale et spirituelle, et l'harmonisation des chants religieux était particulièrement importante, permettant aux communautés de se rassembler dans l'adoration.` },
        { word: 'Innovateur', letters: ['I', 'N', 'N', 'O', 'V', 'A', 'T', 'E', 'U', 'R'], funFact: `Personne qui introduit des nouveautés, souvent dans le domaine technologique ou artistique. Saviez-vous que des innovateurs comme Steve Jobs et Elon Musk ont révolutionné leurs industries respectives avec des idées qui ont changé notre façon de vivre et de travailler ? Jobs a popularisé les ordinateurs personnels avec Apple, tandis que Musk a transformé l'industrie automobile avec Tesla, rendant les véhicules électriques non seulement viables mais aussi désirables. Leur vision continue d'inspirer des entrepreneurs du monde entier.` },
        { word: 'Motivation', letters: ['M', 'O', 'T', 'I', 'V', 'A', 'T', 'I', 'O', 'N'], funFact: `Ensemble des raisons qui poussent à agir. Saviez-vous que la motivation peut être intrinsèque ou extrinsèque ? Les psychologues étudient ces deux types pour comprendre comment les individus atteignent leurs objectifs. Par exemple, un étudiant motivé par l'amour de l'apprentissage (intrinsèque) peut performer différemment de celui qui est motivé par des récompenses externes, comme une bourse d'études (extrinsèque).` },
        { word: 'Normaliser', letters: ['N', 'O', 'R', 'M', 'A', 'L', 'I', 'S', 'E', 'R'], funFact: `Rendre conforme à une norme ou à un standard. Saviez-vous que la normalisation des tailles de vêtements a été introduite pour la première fois par l'industrie textile dans les années 1940 ? Avant cela, les tailles variaient considérablement d'un fabricant à l'autre, ce qui compliquait les achats pour les consommateurs. L'établissement de normes de taille a permis une meilleure expérience d'achat, réduisant le taux de retours et augmentant la satisfaction des clients, un principe qui s'applique encore aujourd'hui dans de nombreuses industries.` },
        { word: 'Occupation', letters: ['O', 'C', 'C', 'U', 'P', 'A', 'T', 'I', 'O', 'N'], funFact: `Action d'occuper un lieu ou une fonction. Saviez-vous que l'occupation d'un territoire a des implications profondes dans l'histoire ? Des événements comme la colonisation ont souvent conduit à des conflits, des échanges culturels et des transformations économiques, marquant profondément les sociétés touchées.` },
        { word: 'Permanence', letters: ['P', 'E', 'R', 'M', 'A', 'N', 'E', 'N', 'C', 'E'], funFact: `État de ce qui est constant ou durable. Saviez-vous que la permanence des glaciers est un sujet de préoccupation en raison du changement climatique ? Les glaciers jouent un rôle crucial dans la régulation du climat, et leur fonte rapide a des répercussions sur les niveaux de la mer et les écosystèmes.` },
        { word: 'Population', letters: ['P', 'O', 'P', 'U', 'L', 'A', 'T', 'I', 'O', 'N'], funFact: `Ensemble des personnes vivant dans une zone donnée. Saviez-vous que la croissance de la population mondiale a des implications profondes sur les ressources naturelles et l'environnement ? Selon les projections des Nations Unies, la population mondiale pourrait atteindre près de 10 milliards d'ici 2050, ce qui soulève des questions sur la durabilité et l'urbanisation.` },
        { word: 'Précaution', letters: ['P', 'R', 'É', 'C', 'A', 'U', 'T', 'I', 'O', 'N'], funFact: `Mesure prise pour éviter un danger ou un risque. Saviez-vous que les précautions de santé publique, comme le lavage des mains, ont été popularisées pendant la pandémie de grippe espagnole en 1918 ? À cette époque, les mesures sanitaires étaient souvent négligées, mais des études ont montré que le lavage des mains pouvait réduire significativement la transmission des maladies. Ces pratiques ont été réintroduites et modernisées, jouant un rôle crucial lors de la pandémie de COVID-19, où le simple acte de se laver les mains est devenu un mantra mondial pour la prévention.` },
        { word: 'Réglementé', letters: ['R', 'É', 'G', 'L', 'E', 'M', 'E', 'N', 'T', 'É'], funFact: `Qui est soumis à des règles ou des lois. Saviez-vous que des secteurs comme l'alimentation, les médicaments et les transports sont strictement réglementés pour garantir la sécurité des consommateurs ? Ces réglementations évoluent en fonction des découvertes scientifiques et des préoccupations sociétales, reflétant les normes éthiques et de sécurité.` },
        { word: 'Rénovation', letters: ['R', 'É', 'N', 'O', 'V', 'A', 'T', 'I', 'O', 'N'], funFact: `Action de remettre à neuf ou de moderniser quelque chose. Saviez-vous que la rénovation des bâtiments historiques est souvent soumise à des règles strictes pour préserver leur caractère ? Dans de nombreuses villes européennes, des efforts considérables sont déployés pour restaurer les façades et les intérieurs tout en intégrant des améliorations modernes, ce qui aide à maintenir l'héritage culturel.` },
        { word: 'Réparateur', letters: ['R', 'É', 'P', 'A', 'R', 'A', 'T', 'E', 'U', 'R'], funFact: `Qui répare quelque chose. Saviez-vous que les réparateurs d'objets anciens, tels que les horlogers et les restaurateurs d'art, jouent un rôle essentiel dans la préservation de notre patrimoine culturel ? Dans certaines régions, des artisans spécialisés utilisent des techniques traditionnelles pour restaurer des œuvres d'art et des objets historiques, ce qui permet non seulement de préserver l'histoire mais aussi d'apprendre des savoir-faire anciens.` },
        { word: 'Répétiteur', letters: ['R', 'É', 'P', 'É', 'T', 'I', 'T', 'E', 'U', 'R'], funFact: `Personne qui répète ou enseigne des matières à d'autres. Saviez-vous que dans le monde de l'éducation, le terme "répétiteur" désigne souvent un enseignant spécialisé dans la préparation d'élèves à des examens ? Ces éducateurs sont particulièrement importants dans les systèmes éducatifs compétitifs, où le soutien supplémentaire peut faire la différence entre réussir et échouer. Ils utilisent des techniques variées, comme la pratique de questions d'examen, pour aider les étudiants à se préparer efficacement.` },
        { word: 'Répétition', letters: ['R', 'É', 'P', 'É', 'T', 'I', 'T', 'I', 'O', 'N'], funFact: `Action de répéter quelque chose. Saviez-vous que la répétition est une technique d'apprentissage reconnue qui aide à ancrer les informations dans la mémoire ? Les étudiants qui révisent régulièrement réussissent généralement mieux aux examens, car la répétition renforce les connexions neuronales, rendant les informations plus faciles à rappeler.` },
        { word: 'Résilience', letters: ['R', 'É', 'S', 'I', 'L', 'I', 'E', 'N', 'C', 'E'], funFact: `Capacité à se remettre d'une situation difficile. Saviez-vous que la résilience est une qualité qui peut être développée ? Les psychologues affirment que les personnes résilientes sont souvent celles qui ont eu des modèles positifs, appris à faire face aux défis et maintenu des liens sociaux solides. Cette qualité est cruciale dans des périodes de crise, comme les catastrophes naturelles.` },
        { word: 'Simplicité', letters: ['S', 'I', 'M', 'P', 'L', 'I', 'C', 'I', 'T', 'É'], funFact: `État de ce qui est simple, sans complication. Saviez-vous que le concept de simplicité est souvent célébré dans le design ? Des designers comme Dieter Rams ont prôné la simplicité fonctionnelle dans leurs créations, affirmant que "moins c'est plus". Cette philosophie a influencé le design moderne et continue d'inspirer des marques dans divers secteurs.` },
        { word: 'Sociologue', letters: ['S', 'O', 'C', 'I', 'O', 'L', 'O', 'G', 'U', 'E'], funFact: `Spécialiste des sciences sociales qui étudie les sociétés et les comportements humains. Saviez-vous que des sociologues comme Émile Durkheim ont établi les bases de la sociologie moderne en étudiant des phénomènes comme le suicide et la solidarité sociale ? Leur travail a transformé notre compréhension de la société et des dynamiques sociales, posant les fondations pour d'autres disciplines comme la psychologie sociale.` },
        { word: 'Soutenable', letters: ['S', 'O', 'U', 'T', 'E', 'N', 'A', 'B', 'L', 'E'], funFact: `Qui peut être soutenu ou maintenu sur le long terme. Saviez-vous que le terme "développement durable" est apparu dans le rapport Brundtland en 1987, qui appelait à un équilibre entre les besoins de développement économique et la protection de l'environnement ? Ce concept a influencé de nombreuses politiques à travers le monde et a mené à la mise en œuvre d'initiatives visant à promouvoir des pratiques soutenables, comme les énergies renouvelables, qui sont cruciales face au changement climatique.` },
        { word: 'Surprenant', letters: ['S', 'U', 'R', 'P', 'R', 'E', 'N', 'A', 'N', 'T'], funFact: `Qui étonne ou surprend. Saviez-vous que certaines espèces d'animaux ont développé des caractéristiques surprenantes pour survivre ? Par exemple, le poulpe est capable de changer de couleur et de texture pour se camoufler dans son environnement, une adaptation impressionnante qui le protège des prédateurs.` },
        { word: 'Téléviseur', letters: ['T', 'E', 'L', 'E', 'V', 'I', 'S', 'E', 'U', 'R'], funFact: `Appareil permettant de recevoir et de diffuser des émissions de télévision. Saviez-vous que le téléviseur a évolué au fil des décennies, passant des modèles à tube cathodique aux écrans plats et aux technologies numériques ? Cette évolution a changé notre façon de consommer les médias, influençant la culture populaire et les habitudes de divertissement.` },
        { word: 'Traducteur', letters: ['T', 'R', 'A', 'D', 'U', 'C', 'T', 'E', 'U', 'R'], funFact: `Personne qui traduit un texte d'une langue à une autre. Saviez-vous que les traducteurs jouent un rôle essentiel dans la communication interculturelle ? Des œuvres littéraires classiques, comme celles de Tolstoï ou de Kafka, n'auraient pas eu le même impact sans les traductions, permettant à des millions de lecteurs de découvrir des histoires et des idées d'autres cultures.` },
        { word: 'Tranquille', letters: ['T', 'R', 'A', 'N', 'Q', 'U', 'I', 'L', 'L', 'E'], funFact: `Qui est calme, sans agitation. Saviez-vous que des études montrent que passer du temps dans des environnements tranquilles peut améliorer la santé mentale ? Des lieux comme les jardins ou les parcs urbains offrent une évasion du stress de la vie quotidienne, favorisant la détente et la réflexion.` },
        { word: 'Transition', letters: ['T', 'R', 'A', 'N', 'S', 'I', 'T', 'I', 'O', 'N'], funFact: `Passage d'un état à un autre. Saviez-vous que la transition énergétique vers des sources renouvelables est devenue une priorité mondiale ? Les pays cherchent à réduire leur dépendance aux combustibles fossiles pour lutter contre le changement climatique, ce qui entraîne des changements significatifs dans les politiques et les infrastructures.` },
        { word: 'Urgentiste', letters: ['U', 'R', 'G', 'E', 'N', 'T', 'I', 'S', 'T', 'E'], funFact: `Personnel médical formé pour intervenir en cas d'urgence. Saviez-vous que les urgentistes jouent un rôle crucial dans les situations de crise, comme les accidents de voiture ou les arrêts cardiaques ? Leur formation rigoureuse leur permet de prendre des décisions rapides et de fournir des soins vitaux qui peuvent sauver des vies.` },
        { word: 'Visualiser', letters: ['V', 'I', 'S', 'U', 'A', 'L', 'I', 'S', 'E', 'R'], funFact: `Se représenter mentalement quelque chose. Saviez-vous que la visualisation est une technique utilisée par de nombreux athlètes pour améliorer leur performance ? En imaginant leurs mouvements et en se concentrant sur des résultats positifs, ils peuvent renforcer leur confiance et leur concentration, ce qui peut mener à des performances améliorées lors des compétitions.` },
        { word: 'Vulnérable', letters: ['V', 'U', 'L', 'N', 'É', 'R', 'A', 'B', 'L', 'E'], funFact: `Qui peut être facilement blessé ou affecté. Saviez-vous que la vulnérabilité est un thème central dans la psychologie moderne ? Les études montrent que reconnaître et exprimer ses vulnérabilités peut renforcer les liens sociaux et améliorer la santé mentale, contrastant avec l'idée traditionnelle de la force émotionnelle.` },
        { word: 'Abandonner', letters: ['A', 'B', 'A', 'N', 'D', 'O', 'N', 'N', 'E', 'R'], funFact: `Abandonner quelque chose signifie quitter ou se dessaisir d'une tâche ou d'un bien. Saviez-vous que dans le droit, abandonner un bien peut entraîner des complications juridiques, car cela signifie qu'aucun propriétaire n'est désigné ? En France, des lois régissent l'abandon de biens, stipulant que les biens abandonnés peuvent être récupérés par l'État après un certain temps, souvent fixé à cinq ans. Et vous, que vous évoque ce mot ?` },
        { word: 'Aménorrhée', letters: ['A', 'M', 'É', 'N', 'O', 'R', 'R', 'H', 'É', 'E'], funFact: `L'aménorrhée est l'absence de menstruations chez une femme. Saviez-vous que l'aménorrhée peut affecter jusqu'à 3% des femmes en âge de procréer ? Cette condition peut être causée par divers facteurs, y compris des troubles alimentaires, le stress ou des déséquilibres hormonaux. Et vous, que vous évoque ce mot ?` },
        { word: 'Aromatiser', letters: ['A', 'R', 'O', 'M', 'A', 'T', 'I', 'S', 'E', 'R'], funFact: `Aromatiser signifie ajouter des arômes à des aliments ou des boissons. Saviez-vous que dans l'Antiquité, les Romains utilisaient plus de 100 types d'épices et d'herbes pour aromatiser leurs plats ? Cela montre à quel point l'art culinaire a toujours été valorisé à travers les âges. Et vous, que vous évoque ce mot ?` },
        { word: 'Assassinat', letters: ['A', 'S', 'S', 'A', 'S', 'S', 'I', 'N', 'A', 'T'], funFact: `L'assassinat fait référence au meurtre planifié d'une personne, souvent pour des motifs politiques. Saviez-vous que l'assassinat de Jules César en 44 av. J.-C. a été un tournant majeur dans l'histoire romaine, marquant la fin de la République romaine et le début de l'Empire ? Cet événement a conduit à des guerres civiles et à la montée de figures politiques comme Octave. Et vous, que vous évoque ce mot ?` },
        { word: 'Biographie', letters: ['B', 'I', 'O', 'G', 'R', 'A', 'P', 'H', 'I', 'E'], funFact: `Biographie désigne un récit de la vie d'une personne. Saviez-vous que les biographies de personnalités célèbres révèlent souvent des aspects surprenants de leur vie ? Par exemple, la biographie de Vincent van Gogh montre qu'il a vendu seulement un tableau de son vivant, malgré son immense talent. Et vous, que vous évoque ce mot ?` },
        { word: 'Changement', letters: ['C', 'H', 'A', 'N', 'G', 'E', 'M', 'E', 'N', 'T'], funFact: `Changement est l'action de changer, de modifier quelque chose. Saviez-vous que le changement climatique a des impacts directs sur les écosystèmes et les sociétés humaines ? Selon le rapport du GIEC, la température mondiale pourrait augmenter de 1,5 °C d'ici 2030, ce qui nécessitera des actions urgentes. Et vous, que vous évoque ce mot ?` },
        { word: 'Construire', letters: ['C', 'O', 'N', 'S', 'T', 'R', 'U', 'I', 'R', 'E'], funFact: `Construire signifie élever ou édifier quelque chose. Saviez-vous que les pyramides d'Égypte continuent de fasciner les archéologues par leur ingénierie ? La Grande Pyramide de Gizeh, construite vers 2580 av. J.-C., a été la plus haute structure du monde pendant plus de 3 800 ans. Et vous, que vous évoque ce mot ?` },
        { word: 'Contribuer', letters: ['C', 'O', 'N', 'T', 'R', 'I', 'B', 'U', 'E', 'R'], funFact: `Contribuer signifie apporter sa part à un effort commun. Saviez-vous que les petites contributions de nombreux individus peuvent aboutir à de grands changements ? Par exemple, des campagnes de financement participatif ont permis de lever des millions d'euros pour des projets artistiques ou environnementaux. Et vous, que vous évoque ce mot ?` },
        { word: 'Déplorable', letters: ['D', 'É', 'P', 'L', 'O', 'R', 'A', 'B', 'L', 'E'], funFact: `Déplorable fait référence à quelque chose qui mérite d'être déploré. Saviez-vous que les conditions de vie déplorables dans certaines régions du monde ont conduit à des mouvements humanitaires, avec des organisations comme Médecins Sans Frontières intervenant dans plus de 70 pays pour aider les populations vulnérables ? Et vous, que vous évoque ce mot ?` },
        { word: 'Développer', letters: ['D', 'É', 'V', 'E', 'L', 'O', 'P', 'P', 'E', 'R'], funFact: `Développer signifie faire croître ou avancer quelque chose. Saviez-vous que le développement de vaccins a permis de prévenir des maladies qui ont autrefois décimé des populations entières ? Par exemple, le vaccin contre la variole a conduit à l'éradication de cette maladie en 1980, après avoir causé des millions de morts. Et vous, que vous évoque ce mot ?` },
        { word: 'Dissonance', letters: ['D', 'I', 'S', 'S', 'O', 'N', 'A', 'N', 'C', 'E'], funFact: `Dissonance désigne l'inharmonie ou le désaccord entre des sons ou des idées. Saviez-vous que en musique, la dissonance est souvent utilisée pour créer une tension ? Des compositeurs comme Igor Stravinsky ont fait usage de la dissonance pour enrichir leurs compositions, influençant le développement de la musique moderne. Et vous, que vous évoque ce mot ?` },
        { word: 'Équanimité', letters: ['É', 'Q', 'U', 'A', 'N', 'I', 'M', 'I', 'T', 'É'], funFact: `Équanimité fait référence à un état d'esprit calme et imperturbable. Saviez-vous que l'équanimité est souvent recherchée dans les pratiques de méditation ? De nombreuses traditions spirituelles, comme le bouddhisme, enseignent l'importance de cultiver l'équanimité pour mieux faire face aux défis de la vie. Et vous, que vous évoque ce mot ?` },
        { word: 'Évaluation', letters: ['É', 'V', 'A', 'L', 'U', 'A', 'T', 'I', 'O', 'N'], funFact: `Évaluation désigne l'action de juger ou d'estimer la valeur de quelque chose. Saviez-vous que les évaluations scolaires sont conçues pour guider l'apprentissage ? En France, le système éducatif utilise un système de notation de 0 à 20 pour évaluer les performances des élèves. Et vous, que vous évoque ce mot ?` },
        { word: 'Générosité', letters: ['G', 'É', 'N', 'É', 'R', 'O', 'S', 'I', 'T', 'É'], funFact: `Générosité fait référence à la disposition à donner sans attendre de retour. Saviez-vous que la générosité a des effets bénéfiques sur la santé mentale et physique ? Des études montrent que donner aux autres peut libérer des endorphines, souvent appelées "l'hormone du bonheur". Et vous, que vous évoque ce mot ?` },
        { word: 'Inexorable', letters: ['I', 'N', 'E', 'X', 'O', 'R', 'A', 'B', 'L', 'E'], funFact: `Inexorable fait référence à quelque chose qui ne peut être empêché. Saviez-vous que l'inexorabilité du temps est un thème récurrent dans la littérature ? Des auteurs comme Marcel Proust et Virginia Woolf ont exploré la nature du temps et son effet sur la mémoire et l'identité. Et vous, que vous évoque ce mot ?` },
        { word: 'Participer', letters: ['P', 'A', 'R', 'T', 'I', 'C', 'I', 'P', 'E', 'R'], funFact: `Participer signifie prendre part à quelque chose. Saviez-vous que participer à des activités communautaires peut renforcer les liens sociaux ? Des recherches montrent que les personnes impliquées dans des activités bénévoles ont tendance à avoir une meilleure santé mentale et un sentiment accru d'appartenance. Et vous, que vous évoque ce mot ?` },
        { word: 'Pertinence', letters: ['P', 'E', 'R', 'T', 'I', 'N', 'E', 'N', 'C', 'E'], funFact: `Pertinence désigne la qualité de ce qui est pertinent, approprié. Saviez-vous que la pertinence des informations est essentielle dans le processus de prise de décision ? Par exemple, dans le marketing, les données pertinentes peuvent augmenter le taux de conversion jusqu'à 50%. Et vous, que vous évoque ce mot ?` },
        { word: 'Téléphoner', letters: ['T', 'É', 'L', 'É', 'P', 'H', 'O', 'N', 'E', 'R'], funFact: `Téléphoner signifie contacter quelqu'un par téléphone. Saviez-vous que l'invention du téléphone par Alexander Graham Bell en 1876 a révolutionné la communication ? Avant cela, les messages étaient envoyés par courrier ou télégraphe, ce qui pouvait prendre des jours. Et vous, que vous évoque ce mot ?` },
        { word: 'Affliction', letters: ['A', 'F', 'F', 'L', 'I', 'C', 'T', 'I', 'O', 'N'], funFact: `L'affliction désigne un état de souffrance ou de douleur. Saviez-vous que les afflictions peuvent être physiques ou émotionnelles, affectant profondément la qualité de vie des individus ? Par exemple, selon l'Organisation mondiale de la santé, environ 264 millions de personnes vivent avec une dépression, un type d'affliction émotionnelle. Et vous, que vous évoque ce mot ?` },
        { word: 'Anticlinal', letters: ['A', 'N', 'T', 'I', 'C', 'L', 'I', 'N', 'A', 'L'], funFact: `Un anticlinal est une structure géologique en forme de dôme où les couches de roches sont pliées vers le haut. Saviez-vous que les anticlinaux sont souvent des zones de recherche pour le pétrole et le gaz naturel ? Des formations comme l'anticlinal de Bakken aux États-Unis sont connues pour leur richesse en ressources énergétiques. Et vous, que vous évoque ce mot ?` },
        { word: 'Antipathie', letters: ['A', 'N', 'T', 'I', 'P', 'A', 'T', 'H', 'I', 'E'], funFact: `L'antipathie désigne un sentiment d'aversion ou de répulsion envers quelque chose ou quelqu'un. Saviez-vous que l'antipathie peut souvent être involontaire, résultant de préjugés inconscients ? Des études psychologiques montrent que les premières impressions jouent un rôle crucial dans le développement de l'antipathie, souvent basées sur des stéréotypes. Et vous, que vous évoque ce mot ?` },
        { word: 'Argumenter', letters: ['A', 'R', 'G', 'U', 'M', 'E', 'N', 'T', 'E', 'R'], funFact: `Argumenter signifie présenter des raisons ou des preuves en faveur d'une idée ou d'une opinion. Saviez-vous que l'art d'argumenter est au cœur de la rhétorique, une discipline enseignée depuis l'Antiquité par des philosophes comme Aristote ? Aujourd'hui, la capacité à argumenter de manière convaincante est une compétence essentielle dans les débats publics et politiques. Et vous, que vous évoque ce mot ?` },
        { word: 'Bangladesh', letters: ['B', 'A', 'N', 'G', 'L', 'A', 'D', 'E', 'S', 'H'], funFact: `Bangladesh est un pays d'Asie du Sud, connu pour sa riche culture et sa biodiversité. Saviez-vous que le Bangladesh est le 8ème pays le plus peuplé du monde, avec plus de 160 millions d'habitants ? Malgré les défis liés à la pauvreté et aux catastrophes naturelles, le pays a connu une croissance économique rapide au cours des dernières décennies. Et vous, que vous évoque ce mot ?` },
        { word: 'Cataclysme', letters: ['C', 'A', 'T', 'A', 'C', 'L', 'Y', 'S', 'M', 'E'], funFact: `Un cataclysme est un événement soudain et violent, souvent de nature naturelle, qui cause des destructions massives. Saviez-vous que des cataclysmes comme le tremblement de terre de 1755 à Lisbonne ont profondément influencé la philosophie et la pensée européenne ? Cet événement a remis en question les croyances religieuses de l'époque et a contribué à la montée du rationalisme. Et vous, que vous évoque ce mot ?` },
        { word: 'Clémentine', letters: ['C', 'L', 'É', 'M', 'E', 'N', 'T', 'I', 'N', 'E'], funFact: `La clémentine est un agrume, fruit d'un hybride entre le mandarin et l'orange amère. Saviez-vous que la clémentine a été introduite en Europe au début du 20ème siècle et est maintenant l'un des fruits les plus consommés au monde ? Selon des études, elle est appréciée pour sa douceur et sa facilité à être pelée, ce qui en fait un en-cas populaire. Et vous, que vous évoque ce mot ?` },
        { word: 'Coercition', letters: ['C', 'O', 'E', 'R', 'C', 'I', 'T', 'I', 'O', 'N'], funFact: `La coercition désigne l'action de contraindre quelqu'un à agir contre sa volonté, souvent par la menace ou la force. Saviez-vous que la coercition est souvent étudiée en droit et en éthique ? Dans de nombreux pays, les lois interdisent les actes de coercition, en particulier dans des contextes comme le travail ou la violence domestique. Et vous, que vous évoque ce mot ?` },
        { word: 'Cogitation', letters: ['C', 'O', 'G', 'I', 'T', 'A', 'T', 'I', 'O', 'N'], funFact: `Cogitation désigne le processus de réflexion profonde ou de méditation. Saviez-vous que le terme "cogito" de Descartes, qui signifie "je pense", est fondamental dans la philosophie moderne ? Cette réflexion a conduit à la célèbre déclaration "Je pense, donc je suis", soulignant l'importance de la pensée dans la définition de l'existence. Et vous, que vous évoque ce mot ?` },
        { word: 'Conclusion', letters: ['C', 'O', 'N', 'C', 'L', 'U', 'S', 'I', 'O', 'N'], funFact: `Une conclusion est le jugement ou l'opinion finale qui découle d'un raisonnement ou d'une analyse. Saviez-vous que les conclusions scientifiques doivent être soutenues par des données et des recherches rigoureuses ? Selon des études, des conclusions erronées peuvent mener à des fausses croyances qui persistent pendant des décennies. Et vous, que vous évoque ce mot ?` },
        { word: 'Conférence', letters: ['C', 'O', 'N', 'F', 'É', 'R', 'E', 'N', 'C', 'E'], funFact: `Une conférence est un rassemblement où des experts discutent d'un sujet particulier. Saviez-vous que les premières conférences académiques modernes ont eu lieu au 19ème siècle, souvent dans des universités prestigieuses ? Ces événements sont devenus des plateformes essentielles pour le partage de connaissances et l'innovation. Et vous, que vous évoque ce mot ?` },
        { word: 'Conviction', letters: ['C', 'O', 'N', 'V', 'I', 'C', 'T', 'I', 'O', 'N'], funFact: `Conviction désigne une forte croyance ou un sentiment de certitude. Saviez-vous que la conviction personnelle est souvent un moteur de changement social ? Des figures emblématiques comme Martin Luther King Jr. ont agi selon leurs convictions, entraînant des mouvements de grande envergure pour les droits civiques. Et vous, que vous évoque ce mot ?` },
        { word: 'Différence', letters: ['D', 'I', 'F', 'F', 'É', 'R', 'E', 'N', 'C', 'E'], funFact: `La différence désigne la distinction entre deux ou plusieurs éléments. Saviez-vous que la différence culturelle est souvent célébrée dans les festivals à travers le monde ? Par exemple, la Journée internationale de la diversité culturelle est observée le 21 mai pour promouvoir la tolérance et la compréhension entre les cultures. Et vous, que vous évoque ce mot ?` },
        { word: 'Discussion', letters: ['D', 'I', 'S', 'C', 'U', 'S', 'S', 'I', 'O', 'N'], funFact: `Discussion désigne un échange de points de vue sur un sujet donné. Saviez-vous que la discussion en groupe est souvent utilisée pour prendre des décisions efficaces dans le cadre professionnel ? Des études montrent que les groupes qui discutent collectivement prennent des décisions plus informées que les individus isolés. Et vous, que vous évoque ce mot ?` },
        { word: 'Ecoanxiété', letters: ['E', 'C', 'O', 'A', 'N', 'X', 'I', 'É', 'T', 'É'], funFact: `Ecoanxiété désigne l'anxiété causée par les préoccupations environnementales. Saviez-vous que cette forme d'anxiété a été reconnue par des psychologues dans les années 2010, alors que de plus en plus de jeunes exprimaient leur inquiétude face aux changements climatiques ? Des initiatives émergent pour aider à faire face à cette anxiété croissante. Et vous, que vous évoque ce mot ?` },
        { word: 'Engagement', letters: ['E', 'N', 'G', 'A', 'G', 'E', 'M', 'E', 'N', 'T'], funFact: `Engagement désigne la promesse de s'impliquer activement dans une cause ou une activité. Saviez-vous que l'engagement civique est crucial pour le bon fonctionnement d'une démocratie ? Selon une étude, des citoyens engagés sont plus susceptibles de voter et de participer aux activités communautaires, renforçant ainsi le tissu social. Et vous, que vous évoque ce mot ?` },
        { word: 'Estimation', letters: ['E', 'S', 'T', 'I', 'M', 'A', 'T', 'I', 'O', 'N'], funFact: `Estimation désigne l'action d'évaluer ou d'estimer une valeur ou une quantité. Saviez-vous que les estimations dans le domaine économique peuvent influencer les décisions politiques ? Par exemple, une estimation incorrecte du PIB peut entraîner des mesures fiscales inappropriées. Cela souligne l'importance des données fiables pour la prise de décision. Et vous, que vous évoque ce mot ?` },
        { word: 'Fulgurance', letters: ['F', 'U', 'L', 'G', 'U', 'R', 'A', 'N', 'C', 'E'], funFact: `Fulgurance désigne un éclat ou une brillance soudaine. Saviez-vous que la fulgurance est souvent utilisée en littérature pour décrire des moments d'illumination ou d'inspiration ? Des écrivains comme Victor Hugo ont évoqué des moments de fulgurance dans leurs œuvres pour capturer la profondeur des émotions humaines. Et vous, que vous évoque ce mot ?` },
    ],
    9: [
        { word: 'Fantastique', letters: ['F', 'A', 'N', 'T', 'A', 'S', 'T', 'I', 'Q', 'U', 'E'], funFact: `Qui relève du domaine de l'imagination, de l'imaginaire. Saviez-vous que le genre fantastique a une longue histoire remontant à l'antiquité? Les contes de fées, les légendes et les mythes sont souvent peuplés de créatures fantastiques telles que les dragons, les fées et les monstres.`, },
        { word: 'Intéressant', letters: ['I', 'N', 'T', 'É', 'R', 'E', 'S', 'S', 'A', 'N', 'T'], funFact: `Qui suscite de l'intérêt ou de la curiosité. Saviez-vous que les recherches montrent que l'intérêt est souvent lié à la dopamine, un neurotransmetteur associé au plaisir et à la motivation? C'est pourquoi nous sommes attirés par les choses que nous trouvons intéressantes.`, },
        { word: 'Environnant', letters: ['E', 'N', 'V', 'I', 'R', 'O', 'N', 'N', 'A', 'N', 'T'], funFact: `Qui entoure ou encadre quelque chose.Saviez-vous que les écosystèmes environnants jouent un rôle crucial dans la survie et la santé des espèces qui y vivent? Les changements dans l'environnement peuvent avoir des répercussions importantes sur la biodiversité et l'équilibre écologique.`, },
        { word: 'Controversé', letters: ['C', 'O', 'N', 'T', 'R', 'O', 'V', 'E', 'R', 'S', 'É'], funFact: `Qui suscite la controverse ou le débat. Saviez-vous que certains sujets, comme la politique, la religion et la morale, sont souvent considérés comme controversés en raison de leurs implications sociales et éthiques? Les controverses peuvent souvent conduire à des discussions constructives mais aussi à des divisions.` },
        { word: 'Affectation', letters: ['A', 'F', 'F', 'E', 'C', 'T', 'A', 'T', 'I', 'O', 'N'], funFact: `L'affectation est l'action d'attribuer ou de désigner quelque chose à un usage spécifique. Saviez-vous que dans le monde scolaire, une affectation peut désigner un devoir à faire à la maison, qui peut représenter jusqu'à 20% du temps d'étude d'un élève ? Ces devoirs sont cruciaux pour renforcer l'apprentissage et sont souvent conçus pour encourager l'autonomie. Et vous, que vous évoque ce mot ?` },
        { word: 'Afghanistan', letters: ['A', 'F', 'G', 'H', 'A', 'N', 'I', 'S', 'T', 'A', 'N'], funFact: `Afghanistan est un pays d'Asie centrale, connu pour ses paysages montagneux et ses conflits. Saviez-vous que l'Afghanistan a une histoire riche, avec des cultures qui se mélangent depuis plus de 2 500 ans ? Le lapis-lazuli, une pierre précieuse bleue, était extrait de la région de Badakhshan et était très prisé dans l'Antiquité, notamment par les Égyptiens pour la création de bijoux et d'ornements. Et vous, que vous évoque ce mot ?` },
        { word: 'Aggravation', letters: ['A', 'G', 'G', 'R', 'A', 'V', 'A', 'T', 'I', 'O', 'N'], funFact: `L'aggravation désigne l'action d'empirer une situation. Saviez-vous que dans le domaine médical, une aggravation d'une maladie peut être un signal d'alarme pour les médecins ? Par exemple, dans le cas de l'asthme, une aggravation des symptômes peut entraîner une hospitalisation dans 20% des cas, soulignant l'importance d'une gestion adéquate de la maladie. Et vous, que vous évoque ce mot ?` },
        { word: 'Aménagement', letters: ['A', 'M', 'É', 'N', 'A', 'G', 'E', 'M', 'E', 'N', 'T'], funFact: `L'aménagement est l'organisation ou l'arrangement d'un espace ou d'un territoire. Saviez-vous que les aménagements urbains peuvent influencer le comportement des habitants ? Des études montrent que des villes qui intègrent des espaces verts voient une augmentation de 30% de l'activité physique de leurs citoyens, améliorant ainsi leur bien-être général. Et vous, que vous évoque ce mot ?` },
        { word: 'Arrachement', letters: ['A', 'R', 'R', 'A', 'C', 'H', 'E', 'M', 'E', 'N', 'T'], funFact: `L'arrachement désigne l'action d'arracher ou de détacher quelque chose. Saviez-vous que l'arrachement dentaire a été une pratique courante dans les temps anciens, souvent réalisée sans anesthésie ? Selon des études, jusqu'à 70% des enfants souffraient de douleurs dentaires à l'époque médiévale, mettant en lumière le besoin d'amélioration des soins dentaires. Et vous, que vous évoque ce mot ?` },
        { word: 'Bifurcation', letters: ['B', 'I', 'F', 'U', 'R', 'C', 'A', 'T', 'I', 'O', 'N'], funFact: `Bifurcation signifie la division en deux branches ou directions. Saviez-vous que en médecine, la bifurcation des artères coronaires est un point critique, car des études montrent que 60% des crises cardiaques surviennent en raison de problèmes dans ces artères ? Cela souligne l'importance de la santé cardiovasculaire. Et vous, que vous évoque ce mot ?` },
        { word: 'Connotation', letters: ['C', 'O', 'N', 'N', 'O', 'T', 'A', 'T', 'I', 'O', 'N'], funFact: `Connotation désigne le sens implicite ou associé d'un mot. Saviez-vous que la connotation d'un mot peut varier d'une culture à l'autre, rendant la traduction parfois délicate ? Par exemple, le mot "héroïque" peut évoquer des sentiments de fierté dans certaines cultures, tandis que dans d'autres, il peut être perçu avec méfiance. Et vous, que vous évoque ce mot ?` },
        { word: 'Instruction', letters: ['I', 'N', 'S', 'T', 'R', 'U', 'C', 'T', 'I', 'O', 'N'], funFact: `Instruction désigne l'action d'enseigner ou de donner des informations. Saviez-vous que l'instruction a évolué pour devenir plus accessible grâce à l'éducation publique ? Au 19ème siècle, des réformes éducatives en Europe ont permis de réduire le taux d'analphabétisme, qui atteignait jusqu'à 90% dans certaines régions. Et vous, que vous évoque ce mot ?` },
        { word: 'Magnanimité', letters: ['M', 'A', 'G', 'N', 'A', 'N', 'I', 'M', 'I', 'T', 'É'], funFact: `Magnanimité fait référence à la grandeur d'âme, à la générosité envers autrui. Saviez-vous que la magnanimité est considérée comme une vertu dans de nombreuses cultures ? Dans l'Antiquité, des figures comme Alexandre le Grand étaient souvent louées pour leur générosité envers leurs adversaires vaincus. Et vous, que vous évoque ce mot ?` },
        { word: 'Motivations', letters: ['M', 'O', 'T', 'I', 'V', 'A', 'T', 'I', 'O', 'N', 'S'], funFact: `Motivations désigne les raisons qui poussent à agir ou à faire quelque chose. Saviez-vous que les motivations intrinsèques, comme le désir d'apprendre, sont souvent plus puissantes que les motivations extrinsèques ? Des études ont montré que les étudiants motivés par l'intérêt personnel réussissent mieux académiquement. Et vous, que vous évoque ce mot ?` },
        { word: 'Pragmatique', letters: ['P', 'R', 'A', 'G', 'M', 'A', 'T', 'I', 'Q', 'U', 'E'], funFact: `Pragmatique fait référence à une approche axée sur l'action et les résultats concrets. Saviez-vous que l'approche pragmatique est souvent privilégiée dans les environnements de travail ? Elle est utilisée pour optimiser les processus et améliorer la productivité, réduisant ainsi les coûts opérationnels. Et vous, que vous évoque ce mot ?` },
        { word: 'Préparation', letters: ['P', 'R', 'É', 'P', 'A', 'R', 'A', 'T', 'I', 'O', 'N'], funFact: `Préparation désigne l'action de préparer, de mettre en place. Saviez-vous que la préparation mentale est tout aussi importante que la préparation physique dans les sports d'élite ? Des études montrent que les athlètes qui s'engagent dans des techniques de visualisation améliorent leurs performances. Et vous, que vous évoque ce mot ?` },
        { word: 'Sociabilité', letters: ['S', 'O', 'C', 'I', 'A', 'B', 'I', 'L', 'I', 'T', 'É'], funFact: `Sociabilité désigne la capacité à interagir et à s'entendre avec les autres. Saviez-vous que la sociabilité est souvent considérée comme une compétence clé pour le succès professionnel ? Des recherches ont révélé que les personnes sociables ont plus de chances d'obtenir des promotions et de réussir dans leurs carrières. Et vous, que vous évoque ce mot ?` },
        { word: 'Statistique', letters: ['S', 'T', 'A', 'T', 'I', 'S', 'T', 'I', 'Q', 'U', 'E'], funFact: `Statistique désigne la science des données, la collecte et l'analyse. Saviez-vous que les statistiques sont essentielles pour comprendre les tendances sociales et économiques ? Par exemple, des études démographiques ont montré que la population mondiale a atteint 8 milliards d'habitants en 2022. Et vous, que vous évoque ce mot ?` },
        { word: 'Utilisation', letters: ['U', 'T', 'I', 'L', 'I', 'S', 'A', 'T', 'I', 'O', 'N'], funFact: `Utilisation désigne l'action d'utiliser quelque chose. Saviez-vous que l'utilisation des smartphones a explosé au cours de la dernière décennie ? En 2021, plus de 3,8 milliards de personnes dans le monde possédaient un smartphone, transformant la manière dont nous communiquons et accédons à l'information. Et vous, que vous évoque ce mot ?` },
        { word: 'Visionnaire', letters: ['V', 'I', 'S', 'I', 'O', 'N', 'N', 'A', 'I', 'R', 'E'], funFact: `Visionnaire désigne une personne qui a une vision de l'avenir, des idées novatrices. Saviez-vous que de nombreux visionnaires, comme Steve Jobs, ont transformé des industries entières grâce à leurs idées audacieuses ? Jobs a souvent été cité pour sa capacité à anticiper les tendances technologiques avant même qu'elles ne deviennent populaires. Et vous, que vous évoque ce mot ?` },
        { word: 'Coalescence', letters: ['C', 'O', 'A', 'L', 'E', 'S', 'C', 'E', 'N', 'C', 'E'], funFact: `La coalescence désigne le processus par lequel deux ou plusieurs choses se réunissent pour former une seule entité. Saviez-vous que la coalescence est un phénomène observé dans la physique des fluides ? Par exemple, dans la formation des gouttes de pluie, des petites gouttes s'unissent pour en former des plus grandes, créant ainsi les précipitations que nous connaissons. Et vous, que vous évoque ce mot ?` },
    ],
    10: [
        { word: 'Exceptionnel', letters: ['E', 'X', 'C', 'E', 'P', 'T', 'I', 'O', 'N', 'N', 'E', 'L'], funFact: `Qui sort de l'ordinaire, qui est remarquable. Saviez-vous que chaque individu possède des caractéristiques exceptionnelles qui le rendent unique? Reconnaître et valoriser ces qualités individuelles peut contribuer à renforcer la confiance en soi et l'estime de soi.`, },
        { word: 'Intelligence', letters: ['I', 'N', 'T', 'E', 'L', 'L', 'I', 'G', 'E', 'N', 'C', 'E'], funFact: `La capacité de comprendre, d'apprendre et de s'adapter à de nouvelles situations. Saviez-vous que l'intelligence humaine est un sujet complexe étudié par divers domaines tels que la psychologie, la neuroscience et l'intelligence artificielle? Malgré de nombreuses théories, il n'existe pas de définition unique de l'intelligence.`, },
        { word: 'Actuellement', letters: ['A', 'C', 'T', 'U', 'E', 'L', 'L', 'E', 'M', 'E', 'N', 'T'], funFact: `À l'heure actuelle, à ce moment précis. Saviez-vous que le terme "actuellement" est souvent utilisé pour signifier "maintenant", mais il est plus précisément utilisé pour faire référence à ce qui se passe actuellement dans une période temporelle plus large? Par exemple, "Actuellement, je travaille sur un projet qui sera terminé dans deux semaines."`, },
        { word: 'Connaissance', letters: ['C', 'O', 'N', 'N', 'A', 'I', 'S', 'S', 'A', 'N', 'C', 'E'], funFact: `La compréhension ou la conscience acquise par l'expérience, l'apprentissage ou l'observation. Saviez-vous que la connaissance est souvent considérée comme l'une des principales sources de pouvoir? C'est pourquoi l'acquisition de connaissances est valorisée dans de nombreuses sociétés à travers le monde.`, },
        { word: 'Bibliothèque', letters: ['B', 'I', 'B', 'L', 'I', 'O', 'T', 'H', 'È', 'Q', 'U', 'E'], funFact: `Une bibliothèque est un lieu où sont rassemblés et classés des livres, des documents, des périodiques ou d'autres supports d'information, accessibles au public pour l'étude, la lecture ou la consultation. Saviez-vous que la plus grande bibliothèque du monde est la Bibliothèque du Congrès à Washington, D.C. ? Fondée en 1800, elle abrite plus de 170 millions d'articles, dont des livres, des manuscrits, des cartes, des photographies et même des partitions musicales. Elle est non seulement une mine d'informations pour les chercheurs du monde entier, mais aussi un lieu emblématique de la culture et de l'histoire américaine.` },
        { word: 'Impressionné', letters: ['I', 'M', 'P', 'R', 'E', 'S', 'S', 'I', 'O', 'N', 'N', 'É'], funFact: `Être touché ou influencé par une expérience. Saviez-vous que les artistes impressionnistes furent d’abord critiqués avant de révolutionner l’art avec leur nouvelle approche de la lumière ?` },
        { word: 'Perfectionné', letters: ['P', 'E', 'R', 'F', 'E', 'C', 'T', 'I', 'O', 'N', 'N', 'É'], funFact: `Amélioré jusqu’à atteindre un haut niveau. Saviez-vous que Léonard de Vinci consacra des années à perfectionner La Joconde, un chef-d’œuvre emblématique de la Renaissance ?` },
        { word: 'Caractériser', letters: ['C', 'A', 'R', 'A', 'C', 'T', 'É', 'R', 'I', 'S', 'E', 'R'], funFact: `Décrire les caractéristiques d'un objet ou être. Saviez-vous qu’en littérature, les personnages sont souvent caractérisés par des traits distincts pour les rendre inoubliables ?` },
        { word: 'Installation', letters: ['I', 'N', 'S', 'T', 'A', 'L', 'L', 'A', 'T', 'I', 'O', 'N'], funFact: `Mise en place ou assemblage de matériel. Saviez-vous que dans l’art contemporain, les installations sont des œuvres immersives souvent créées pour des galeries ?` },
        { word: 'Instrumental', letters: ['I', 'N', 'S', 'T', 'R', 'U', 'M', 'E', 'N', 'T', 'A', 'L'], funFact: `Relatif aux instruments ou utilisé comme moyen pour atteindre un but. Saviez-vous que la musique instrumentale existe depuis l'Antiquité et joue un rôle dans les cérémonies religieuses et les rituels ?` },
        { word: 'Démocratique', letters: ['D', 'É', 'M', 'O', 'C', 'R', 'A', 'T', 'I', 'Q', 'U', 'E'], funFact: `Relatif à la démocratie, un système où le peuple détient le pouvoir. Saviez-vous que la démocratie moderne trouve ses racines dans la Grèce antique, où seuls les citoyens hommes libres votaient ?` },
        { word: 'Emmagasinage', letters: ['E', 'M', 'M', 'A', 'G', 'A', 'S', 'I', 'N', 'A', 'G', 'E'], funFact: `Stockage de produits en grande quantité. Saviez-vous que les premiers greniers remontent à 12 000 ans, permettant aux sociétés agricoles de survivre à des famines ?` },
        { word: 'Illumination', letters: ['I', 'L', 'L', 'U', 'M', 'I', 'N', 'A', 'T', 'I', 'O', 'N'], funFact: `Effet ou action d’éclairer un lieu. Saviez-vous que Paris, la "Ville Lumière", a été la première ville à instaurer l’éclairage public au XVIIe siècle ?` },
        { word: 'Résurrection', letters: ['R', 'É', 'S', 'U', 'R', 'R', 'E', 'C', 'T', 'I', 'O', 'N'], funFact: `Retour à la vie, concept souvent spirituel. Saviez-vous que les Égyptiens pratiquaient la momification, espérant préserver le corps pour la vie après la mort ?` },
        { word: 'Revitalisant', letters: ['R', 'E', 'V', 'I', 'T', 'A', 'L', 'I', 'S', 'A', 'N', 'T'], funFact: `Qui redonne de l'énergie. Saviez-vous que les bains thermaux sont utilisés depuis la Rome antique pour leurs effets revitalisants sur le corps ?` },
        { word: 'Immatriculer', letters: ['I', 'M', 'M', 'A', 'T', 'R', 'I', 'C', 'U', 'L', 'E', 'R'], funFact: `Enregistrer un véhicule pour lui attribuer une plaque. Saviez-vous que les premières plaques sont apparues en France en 1893 pour réguler le trafic naissant ?` },
        { word: 'Légitimation', letters: ['L', 'É', 'G', 'I', 'T', 'I', 'M', 'A', 'T', 'I', 'O', 'N'], funFact: `Rendre quelque chose légal ou moralement acceptable. Saviez-vous qu’une loi, sans l’accord du public, risque de perdre sa légitimité ?` },
        { word: 'Mécanisation', letters: ['M', 'É', 'C', 'A', 'N', 'I', 'S', 'A', 'T', 'I', 'O', 'N'], funFact: `Introduction de machines dans un processus pour améliorer l’efficacité. Saviez-vous que la mécanisation a débuté avec la révolution industrielle, transformant la production ?` },
        { word: 'Colonisateur', letters: ['C', 'O', 'L', 'O', 'N', 'I', 'S', 'A', 'T', 'E', 'U', 'R'], funFact: `Personne ou pays qui prend possession d'un territoire étranger. Saviez-vous que les empires coloniaux ont contribué à des échanges culturels, mais souvent au prix de violences et d’exploitation ?` },
        { word: 'Rationaliser', letters: ['R', 'A', 'T', 'I', 'O', 'N', 'A', 'L', 'I', 'S', 'E', 'R'], funFact: `Rendre un processus plus logique et efficace. Saviez-vous que la rationalisation industrielle a commencé avec l’organisation scientifique du travail de Taylor au début du XXe siècle ?` },
        { word: 'Effervescent', letters: ['E', 'F', 'F', 'E', 'R', 'V', 'E', 'S', 'C', 'E', 'N', 'T'], funFact: `Qui produit des bulles de gaz et donne une impression d'agitation. Saviez-vous que l’eau effervescente était prisée dans les bains thermaux romains pour ses effets relaxants et purifiants ?` },
        { word: 'Légalisation', letters: ['L', 'É', 'G', 'A', 'L', 'I', 'S', 'A', 'T', 'I', 'O', 'N'], funFact: `Action de rendre quelque chose légal. Saviez-vous que la légalisation du mariage civil en France en 1792 a permis aux citoyens de se marier sans passer par l’église, un changement radical pour l’époque ?` },
        { word: 'Biodiversité', letters: ['B', 'I', 'O', 'D', 'I', 'V', 'E', 'R', 'S', 'I', 'T', 'É'], funFact: `Variété des espèces vivantes dans un écosystème donné. Saviez-vous que plus de 80 % des espèces terrestres et marines restent encore à découvrir ? Cette biodiversité inexploitée peut être source d’innovations en médecine et agriculture.` },
        { word: 'Exploitation', letters: ['E', 'X', 'P', 'L', 'O', 'I', 'T', 'A', 'T', 'I', 'O', 'N'], funFact: `Utilisation de ressources pour un profit. Saviez-vous que l’exploitation rapide des ressources naturelles menace la durabilité écologique de nombreuses régions ?` },
        { word: 'Fragmenterai', letters: ['F', 'R', 'A', 'G', 'M', 'E', 'N', 'T', 'E', 'R', 'A', 'I'], funFact: `Diviser en plusieurs morceaux ou parties. Saviez-vous que dans les chaînes de montagnes, les roches sont fragmentées par le gel, un processus de fragmentation appelé gélifraction ?` },
        { word: 'Interruption', letters: ['I', 'N', 'T', 'E', 'R', 'R', 'U', 'P', 'T', 'I', 'O', 'N'], funFact: `Arrêt momentané ou définitif d'une action. Saviez-vous que des interruptions fréquentes au travail réduisent la productivité de 40 %, d’où la création de plages sans interruption ?` },
        { word: 'Finalisation', letters: ['F', 'I', 'N', 'A', 'L', 'I', 'S', 'A', 'T', 'I', 'O', 'N'], funFact: `Dernière étape d’un processus. Saviez-vous que la finalisation d’un projet peut nécessiter plus de temps que prévu, car les ajustements et vérifications de qualité y jouent un rôle crucial ?` },
        { word: 'Chlorophylle', letters: ['C', 'H', 'L', 'O', 'R', 'O', 'P', 'H', 'Y', 'L', 'L', 'E'], funFact: `Pigment vert des plantes qui permet la photosynthèse. Saviez-vous que la chlorophylle convertit le CO₂ en oxygène ? Plus de 50 % de l'oxygène de l’atmosphère est produit par les plantes.` },
        { word: 'Coordination', letters: ['C', 'O', 'O', 'R', 'D', 'I', 'N', 'A', 'T', 'I', 'O', 'N'], funFact: `Organisation d'actions pour atteindre un but commun. Saviez-vous que la coordination motrice chez l’enfant se développe grâce à des exercices comme la danse et le sport ?` },
        { word: 'Électrolyser', letters: ['É', 'L', 'E', 'C', 'T', 'R', 'O', 'L', 'Y', 'S', 'E', 'R'], funFact: `Procédé chimique décomposant une substance par l’électricité. Saviez-vous que l'électrolyse permet de produire de l’hydrogène, une alternative aux énergies fossiles ?` },
        { word: 'Électronique', letters: ['É', 'L', 'E', 'C', 'T', 'R', 'O', 'N', 'I', 'Q', 'U', 'E'], funFact: `Science des circuits électriques et composants. Saviez-vous que l’électronique moderne a transformé notre vie avec des milliards de puces produites chaque année ?` },
        { word: 'Élémentation', letters: ['É', 'L', 'É', 'M', 'E', 'N', 'T', 'A', 'T', 'I', 'O', 'N'], funFact: `Division en éléments distincts en chimie. Saviez-vous que cette analyse remonte aux alchimistes qui cherchaient à comprendre la matière en la décomposant ?` },
        { word: 'Numérotation', letters: ['N', 'U', 'M', 'É', 'R', 'O', 'T', 'A', 'T', 'I', 'O', 'N'], funFact: `Attribution de numéros pour classer des éléments. Saviez-vous que la classification décimale de Dewey est encore utilisée dans les bibliothèques modernes ?` },
        { word: 'Optimisation', letters: ['O', 'P', 'T', 'I', 'M', 'I', 'S', 'A', 'T', 'I', 'O', 'N'], funFact: `Amélioration d'un processus pour le rendre plus efficace. Saviez-vous que l’optimisation est au cœur des logistiques modernes, permettant de réduire les coûts et l’empreinte écologique ?` },
        { word: 'Régénération', letters: ['R', 'É', 'G', 'É', 'N', 'É', 'R', 'A', 'T', 'I', 'O', 'N'], funFact: `Action de renouveler ou restaurer quelque chose. Saviez-vous que des créatures comme la salamandre peuvent régénérer des membres perdus ? Ce processus inspire la recherche en médecine.` },
        { word: 'Télépathique', letters: ['T', 'É', 'L', 'É', 'P', 'A', 'T', 'H', 'I', 'Q', 'U', 'E'], funFact: `Relatif à la télépathie, transmission de pensées sans support physique. Saviez-vous que des recherches en neurotechnologie explorent des moyens de communication par la pensée ?` },
        { word: 'Numérisation', letters: ['N', 'U', 'M', 'É', 'R', 'I', 'S', 'A', 'T', 'I', 'O', 'N'], funFact: `Conversion d'éléments en format numérique. Saviez-vous que des manuscrits anciens sont numérisés pour les préserver et les rendre accessibles au grand public ?` },
        { word: 'Polarisation', letters: ['P', 'O', 'L', 'A', 'R', 'I', 'S', 'A', 'T', 'I', 'O', 'N'], funFact: `Processus par lequel une onde ou une particule prend une orientation privilégiée. Saviez-vous que la polarisation de la lumière est utilisée dans les lunettes de soleil pour bloquer les reflets ? Elle joue aussi un rôle dans les écrans LCD.` },
        { word: 'Calculatrice', letters: ['C', 'A', 'L', 'C', 'U', 'L', 'A', 'T', 'R', 'I', 'C', 'E'], funFact: `Appareil permettant de réaliser des opérations mathématiques. Saviez-vous que la première calculatrice mécanique a été inventée en 1642 par Blaise Pascal, pour aider son père à faire ses comptes ?` },
        { word: 'Identifierai', letters: ['I', 'D', 'E', 'N', 'T', 'I', 'F', 'I', 'E', 'R', 'A', 'I'], funFact: `Reconnaître ou définir l’identité d’un élément ou d'une personne. Saviez-vous que l’empreinte digitale est utilisée depuis 1892 pour identifier les individus de manière unique ?` },
        { word: 'Modificateur', letters: ['M', 'O', 'D', 'I', 'F', 'I', 'C', 'A', 'T', 'E', 'U', 'R'], funFact: `Élément qui change ou altère quelque chose. Saviez-vous que les modificateurs génétiques sont au cœur des recherches sur la thérapie génique, ouvrant de nouvelles perspectives en médecine ?` },
        { word: 'Approximatif', letters: ['A', 'P', 'P', 'R', 'O', 'X', 'I', 'M', 'A', 'T', 'I', 'F'], funFact: `Qui est proche mais non exact. Saviez-vous que les estimations approximatives sont souvent utilisées en sciences pour prédire des phénomènes complexes, comme les prévisions météorologiques ?` },
        { word: 'Assainisseur', letters: ['A', 'S', 'S', 'A', 'I', 'N', 'I', 'S', 'S', 'E', 'U', 'R'], funFact: `Produit ou dispositif qui purifie un espace ou un élément. Saviez-vous que les premiers assainisseurs d’air ont été créés pour les hôpitaux au XIXe siècle, afin de prévenir les infections ?` },
        { word: 'Confidentiel', letters: ['C', 'O', 'N', 'F', 'I', 'D', 'E', 'N', 'T', 'I', 'E', 'L'], funFact: `Qui est destiné à rester secret ou privé. Saviez-vous que les documents confidentiels sont souvent protégés par cryptographie dans le monde numérique pour garantir leur confidentialité ?` },
        { word: 'Observatoire', letters: ['O', 'B', 'S', 'E', 'R', 'V', 'A', 'T', 'O', 'I', 'R', 'E'], funFact: `Installation dédiée à l’observation scientifique, souvent des étoiles. Saviez-vous que l'observatoire du Mont Palomar a permis de découvrir plus de 200 galaxies inconnues jusque-là ?` },
        { word: 'Perturbateur', letters: ['P', 'E', 'R', 'T', 'U', 'R', 'B', 'A', 'T', 'E', 'U', 'R'], funFact: `Qui provoque des désordres ou troubles. Saviez-vous que certains produits chimiques perturbateurs endocriniens influencent la santé humaine, d’où l’importance des réglementations pour limiter leur usage ?` },
        { word: 'Améliorateur', letters: ['A', 'M', 'É', 'L', 'I', 'O', 'R', 'A', 'T', 'E', 'U', 'R'], funFact: `Élément qui rend quelque chose meilleur. Saviez-vous que dans l’industrie alimentaire, les améliorateurs de pain sont utilisés pour prolonger la durée de conservation et améliorer la texture ?` },
        { word: 'Renouvelable', letters: ['R', 'E', 'N', 'O', 'U', 'V', 'E', 'L', 'A', 'B', 'L', 'E'], funFact: `Qui peut être remplacé ou reproduit naturellement. Saviez-vous que les énergies renouvelables, comme le solaire et l’éolien, pourraient couvrir 80 % des besoins énergétiques mondiaux d'ici 2050 ?` },
        { word: 'Systématique', letters: ['S', 'Y', 'S', 'T', 'É', 'M', 'A', 'T', 'I', 'Q', 'U', 'E'], funFact: `Qui se rapporte à un système organisé ou une méthode rigoureuse. Saviez-vous que la systématique, en biologie, permet de classer les espèces selon leur relation évolutive, aidant ainsi à mieux comprendre la biodiversité ?` },
        { word: 'Mobilisateur', letters: ['M', 'O', 'B', 'I', 'L', 'I', 'S', 'A', 'T', 'E', 'U', 'R'], funFact: `Qui incite à agir ou à se mobiliser. Saviez-vous que certaines causes comme l’écologie ou les droits civiques mobilisent des millions de personnes à travers le monde, grâce aux réseaux sociaux ?` },
        { word: 'Disqualifier', letters: ['D', 'I', 'S', 'Q', 'U', 'A', 'L', 'I', 'F', 'I', 'E', 'R'], funFact: `Exclure quelqu’un d'une compétition pour non-respect des règles. Saviez-vous que les athlètes peuvent être disqualifiés pour des infractions comme le dopage, qui altère la performance de façon injuste ?` },
        { word: 'Commandement', letters: ['C', 'O', 'M', 'M', 'A', 'N', 'D', 'E', 'M', 'E', 'N', 'T'], funFact: `Commandement fait référence à l'autorité ou au pouvoir de commander. Saviez-vous que dans le monde militaire, le commandement est crucial pour la coordination des opérations ? Une étude a montré que 80% des décisions stratégiques en temps de guerre reposent sur une communication efficace entre le commandement et les troupes. Et vous, que vous évoque ce mot ?` },
        { word: 'Interdiction', letters: ['I', 'N', 'T', 'E', 'R', 'D', 'I', 'C', 'T', 'I', 'O', 'N'], funFact: `Interdiction désigne l'action d'interdire quelque chose. Saviez-vous que les interdictions légales peuvent avoir des impacts profonds sur le comportement social ? La prohibition de l'alcool aux États-Unis dans les années 1920 a conduit à une augmentation de la criminalité organisée, montrant ainsi l'effet de telles politiques sur la société. Et vous, que vous évoque ce mot ?` },
        { word: 'Intervention', letters: ['I', 'N', 'T', 'E', 'R', 'V', 'E', 'N', 'T', 'I', 'O', 'N'], funFact: `Intervention désigne l'action d'intervenir pour influencer une situation. Saviez-vous que les interventions médicales ont considérablement évolué ? Par exemple, la chirurgie cardiaque est devenue une pratique courante dans les années 1960, sauvant des millions de vies depuis lors. Et vous, que vous évoque ce mot ?` },
        { word: 'Surveillance', letters: ['S', 'U', 'R', 'V', 'E', 'I', 'L', 'L', 'A', 'N', 'C', 'E'], funFact: `Surveillance désigne l'action de surveiller, d'observer attentivement. Saviez-vous que la surveillance est devenue un sujet de débat dans les sociétés modernes ? Des rapports indiquent que la technologie de surveillance a été utilisée dans des contextes aussi variés que la sécurité publique et le marketing, soulevant des questions éthiques. Et vous, que vous évoque ce mot ?` },
        { word: 'Vérification', letters: ['V', 'É', 'R', 'I', 'F', 'I', 'C', 'A', 'T', 'I', 'O', 'N'], funFact: `Vérification désigne l'action de vérifier, de s'assurer de la conformité. Saviez-vous que la vérification des faits est devenue cruciale dans le journalisme ? Des études montrent que plus de 60% des consommateurs s'informent davantage sur l'origine des nouvelles en raison de la propagation de fausses informations. Et vous, que vous évoque ce mot ?` },
    ],
}
