/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Card = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        max-height: max-content;
    `}
`;
