import { useEffect } from 'react';
import GamesComponent from '../components/GamesComponent';
import { ScreenOrientation } from '@capacitor/screen-orientation';

const Games = () => {

  useEffect(() => {
    try {
      ScreenOrientation.unlock();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <GamesComponent />
  );
};

export default Games;