import React, { ReactNode } from 'react';

import Action from '../Action';
import Heading from '../Heading';

import styleCss from './style.module.css';

type CardHeaderProps = {
    title?: string;
    titleIcon?: string;
    cardHasContent?: boolean;
};

const CardHeader: React.FC<CardHeaderProps> = ({ title = '', titleIcon = null, cardHasContent = false }) => {
    if (!title && !titleIcon) {
        return null;
    }

    return (
        <div className={`${styleCss.cardHeader} ${cardHasContent ? styleCss.withContent : ''}`}>
            <Heading h={3} className={styleCss.title}>
                {titleIcon && <img src={titleIcon} />}
                {title}
            </Heading>
        </div>
    );
};

type CardProps = {
    children?: ReactNode;
    title?: string;
    titleIcon?: string;
    className?: string;
    contentClassName?: string;
    action?: string | (() => void);
    style?: React.CSSProperties;
    padding?: boolean;
    margin?: boolean;
    transparent?: boolean;
    contentHeight?: { [key: string]: React.CSSProperties };
};

const Card: React.FC<CardProps> = ({
    children,
    title = '',
    titleIcon,
    className = '',
    contentClassName = '',
    action = '',
    style = {},
    padding = true,
    margin = true,
    transparent = false,
    contentHeight,
}) => {
    const computedClass = `${styleCss.card} ${margin ? styleCss.margin : ''} ${
        transparent ? styleCss.transparent : ''
    } ${className}`;

    const area = (
        <>
            <CardHeader
                title={title}
                titleIcon={titleIcon}
                cardHasContent={!!children}
            />
            <div
                className={`${styleCss.content} ${contentHeight ? styleCss.maxHeight : ''} ${
                    padding ? styleCss.padding : ''
                } ${contentClassName}`}
                style={contentHeight !== undefined ? { '--max-content-height': contentHeight } as React.CSSProperties : undefined}
            >
                {children}
            </div>
        </>
    );

    if (action) {
        return (
            <Action className={computedClass} action={action} style={style}>
                {area}
            </Action>
        );
    }

    return (
        <div className={computedClass} style={style}>
            {area}
        </div>
    );
};

export default Card;
