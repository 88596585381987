import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import App from './App';
import './main.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { isPlatform } from '@ionic/react';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, DEVICE, PRODUCTION, SENTRY_DSN } from './config';

const container = document.getElementById('root');
const root = createRoot(container!);

export const returnTo = isPlatform('hybrid')
    ? 'fr.stimulin://home'
    : window.location.origin;

if (PRODUCTION) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            browserTracingIntegration({
                tracePropagationTargets: ['localhost', 'https://appv1.stimulin.fr']
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0
    });
}

console.log('DEVICE', DEVICE);

document.documentElement.classList.add(DEVICE.toLowerCase());

root.render(
   // <React.StrictMode>
        <Auth0Provider
            domain={AUTH0_DOMAIN || ''}
            clientId={AUTH0_CLIENT_ID  || ''}
            useRefreshTokens={true}
            useRefreshTokensFallback={false}
            cacheLocation="localstorage"
            authorizationParams={{
                // token_endpoint_auth_method: 'none',
                // application_type: isPlatform('hybrid') ? "Native" : "SPA",
                redirect_uri: returnTo,
                audience: AUTH0_AUDIENCE,
            }}>
            <App />
        </Auth0Provider>
    // </React.StrictMode>
);
