import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { devToolsEnhancer } from 'redux-devtools-extension';

import reducer from '../../store';

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function configureStore() {
    const store = createStore(persistedReducer, devToolsEnhancer({}));
    const persistor = persistStore(store);
    return { persistor, store };
}

// eslint-disable-next-line import/no-default-export
export default configureStore;
