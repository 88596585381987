import { BrowserRouter } from 'react-router-dom';
import './i18n/config';
import {
    IonApp,
    IonPage,
    IonRouterOutlet,
    isPlatform,
    setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Analytics } from '@vercel/analytics/react';
import { UserProvider } from './providers/UserProvider';
import * as Sentry from '@sentry/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Router } from './routes';
import { ThemeConfig } from './theme';
import { WindowProvider } from './providers/WindowProvider';
import { MenuProvider } from './providers/MenuProvider';
import { PRODUCTION } from './config';

config.autoAddCss = false;

defineCustomElements(window);

setupIonicReact();

export default function App(): JSX.Element {

    return (
        <Sentry.ErrorBoundary
            fallback={({ error }) => <div>{error.toString()}</div>}>
            <IonApp style={isPlatform('ios') && isPlatform('hybrid') ? {
                marginTop: 'var(--ion-safe-area-top)',
                marginBottom: 'var(--ion-safe-area-bottom)',
                marginLeft: 'var(--ion-safe-area-left)',
                marginRight: 'var(--ion-safe-area-right)',
            } : {}}>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <ThemeConfig>
                            <BrowserRouter>
                                <UserProvider>
                                    <MenuProvider>
                                        <WindowProvider>
                                            <IonPage
                                                style={{
                                                    backgroundColor: '#fff',
                                                }}>
                                                <Router />
                                            </IonPage>
                                        </WindowProvider>
                                    </MenuProvider>
                                </UserProvider>
                            </BrowserRouter>
                        </ThemeConfig>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
            {PRODUCTION && <Analytics />}
        </Sentry.ErrorBoundary>
    );
}
