import { withTranslation, WithTranslation } from "react-i18next";

import style from "./style.module.css";
import EinsteinSpeaking from "../EinsteinSpeaking";
import TabBar from "../TabBar";
import NavBar from "../NavBar";
import Photo from './assets/photo.svg?react';
import Video from './assets/video.svg?react';
import Youtube from './assets/youtube.svg?react';
import MediaGrid from "../MediaGrid";
import { MediaItem } from "../../models/Media";
import HomeHeader from "../HomeHeader";
import { useContext, useEffect, useState } from "react";
import { UserDataProp } from "../../models/userDataProp";
import { UserContext } from "../../providers/UserProvider";
import { deleteMedia } from "../../stores/Media";
import { useMenu } from "../../contexts/MenuContext";
import { DeleteWindow } from "../../windows/Delete";
import adminSendAllMedia from "../../assets/adminSendAllMedia.jpg";
import ContextMenu from "../../pages/ContextMenu";
import Action from "../Action";

interface AlbumComponentProps extends WithTranslation {
    media: MediaItem[];
    onDisplay: (id: string) => void;
    openAdminModal?: () => void;
};

const AlbumComponent = ({ t, media: initialMedia, onDisplay, openAdminModal }: AlbumComponentProps) => {
    const [filter, setFilter] = useState<string>('ALL');
    const { openMenu, closeMenu } = useMenu();
    const [media, setMedia] = useState<MediaItem[]>(initialMedia);
    const userDataProp: UserDataProp = useContext(UserContext);

    useEffect(() => {
        setMedia(initialMedia);
    }, [initialMedia]);

    const handleEdit = (mediaId: string) => {
        const mediaItem = media.find((item: MediaItem) => item.id === mediaId);
        if (openMenu && closeMenu && mediaItem) {
            openMenu({
                component: () => <ContextMenu
                    initialPage='IMPORT'
                    onClose={() => closeMenu()}
                    media={
                        {
                            type: mediaItem.type === 'IMAGE' ? 'picture' : mediaItem.type === 'VIDEO' ? 'personal-video' : 'youtube-video',
                            id: mediaItem.id,
                            videoUrl: mediaItem.url,
                            thumbnail: mediaItem.thumbnail,
                        }
                    }
                />,
            });
        }
    };

    const handleDelete = async (mediaId: string) => {
        if (openMenu && closeMenu) {
            openMenu({
                component: () => <DeleteWindow type={'media'} onCancel={closeMenu} onDelete={() => {
                    deleteMedia(userDataProp?.token || '', mediaId);
                    setMedia(prevMedia => prevMedia.filter(item => item.id !== mediaId));
                    closeMenu();
                }} />,
            });
        }
    }

    return (
        <div className={style.root}>
            <HomeHeader />
            {openAdminModal && <Action action={openAdminModal}>
                <img className={style.adminSendAllMedia} src={adminSendAllMedia} alt="Send all media" />
            </Action>}
            <EinsteinSpeaking
                icon='media'
            >
                <span>{userDataProp?.user?.firstName},</span>
                <span className={style.subTitle}>{t(' découvrez vos médias …')}</span>
            </EinsteinSpeaking>
            <TabBar
                onSelected={(value) => setFilter(value)}
                items={[
                    { label: t('Tous mes médias'), value: 'ALL', active: filter === 'ALL' },
                    { label: t('Photos'), icon: Photo, value: 'IMAGE', active: filter === 'IMAGE' },
                    { label: t('Vidéos'), icon: Video, value: 'VIDEO', active: filter === 'VIDEO' },
                    { label: t('Contenus Youtube©'), icon: Youtube, value: 'YOUTUBE', active: filter === 'YOUTUBE' },
                ]}
            />
            <MediaGrid
                type={filter}
                withAlbum
                onDisplay={onDisplay}
                items={media
                    // Filter media by type
                    ?.filter((item) => filter === 'ALL' || item.type === filter)
                    // Sort media by created_date
                    ?.sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
                    ?.map((item) => ({
                        id: item.id,
                        media: item.type,
                        title: item.title,
                        image: item.thumbnail || item.url,
                        author: item.authorName,
                        authorImage: item.authorPicture,
                        permission: item.permission,
                        favorite: false,
                        new: false,
                    }))}
                onEdit={(id) => handleEdit(id)}
                onDelete={(id) => handleDelete(id)}
            />
            <NavBar />
        </div>
    );
};

export default withTranslation()(AlbumComponent);
