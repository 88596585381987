import { useContext, useEffect, useState } from 'react';
import { Loader } from '../components/Loader';
import NewsComponent from '../components/NewsComponent';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { useGame } from '../stores/Game';
import { useMedia, useOneMedia } from '../stores/Media';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import Funfact from './games/Funfact';

const News = () => {
  const userDataProp: UserDataProp | null = useContext(UserContext);
  const { data: dataGame, loading: loadingGame, error: errorGame } = useGame(userDataProp?.token || '', 'all');
  const { data: dataMedia, loading: loadingMedia, error: errorMedia } = useMedia(userDataProp?.token || '');
  const [mediaId, setMediaId] = useState<string>('');
  const { data: selectedMedia, loading: loadingMediaSelected, error: errorMediaSelected } = useOneMedia(mediaId, userDataProp?.token || '');

  useEffect(() => {
    try {
      ScreenOrientation.unlock();
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (loadingGame || loadingMedia) {
    return <Loader />;
  }

  if (errorGame || errorMedia) {
    return <div>Error: {errorGame?.message || errorMedia?.message}</div>;
  }

  const news =
    [...dataGame, ...dataMedia]
      .filter((item) => item.authorName !== `${userDataProp?.user?.firstName} ${userDataProp?.user?.lastName}`)
      .sort((a, b) => {
        const dateA = new Date(b.created_date).getTime();
        const dateB = new Date(a.created_date).getTime();
        return dateA - dateB;
      })
      .slice(0, 8);

  if (mediaId) {
    if (loadingMediaSelected) {
      return <Loader />;
    }

    if (errorMediaSelected) {
      console.error(errorMediaSelected);
      return <div>Error: {errorMediaSelected?.message}</div>;
    }

    const handleCloseMedia = () => {
      setMediaId('');
    };

    return <Funfact
      mode='album'
      type={selectedMedia?.type || 'IMAGE'}
      title={selectedMedia?.title || ''}
      Picture={selectedMedia?.url || ''}
      text={selectedMedia?.anecdote || ''}
      onClose={handleCloseMedia}
    />
  }

  return (
    <NewsComponent data={news} onDisplay={(id) => setMediaId(id)} />
  );
};

export default News;