import { withTranslation, WithTranslation } from 'react-i18next';

import style from './style.module.css';
import Alert from '../Alert';
import { FieldError } from 'react-hook-form';

interface FormGroupProps extends WithTranslation {
    label?: string;
    id?: string;
    required?: boolean;
    error?: FieldError;
    children: React.ReactNode;
    allColumns?: boolean;
    inline?: boolean;
}

const FormGroup = ({
    label = '',
    id = undefined,
    required = false,
    error = undefined,
    children,
    t,
    allColumns = false,
    inline = false,
}: FormGroupProps) => {
    return (
        <div className={`${style.formGroup} ${allColumns ? style.allColumns : ''}`}>
            {label && (
                <label htmlFor={id} className={style.label}>
                    {required ? `${label}*` : label}
                </label>
            )}
            <div className={`${inline ? style.inline : ''}`}>{children}</div>
            {error && (
                <Alert type="DANGER" transparent>
                    {error.message}
                </Alert>
            )}
        </div>
    );
};

export default withTranslation()(FormGroup);
