import style from './style.module.css';

interface AlertProps {
    type: 'DANGER' | 'SUCCESS' | 'INFO' | 'WARNING';
    transparent?: boolean;
    children: React.ReactNode;
}

const Alert = ({ type, transparent, children }: AlertProps) => {
    return <div className={`${style.alert} ${style[type.toLowerCase()]} ${transparent ? style.transparent : ''}`}>{children}</div>;
};

export default Alert;