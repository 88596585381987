import React from 'react';

import style from './style.module.css';

interface InputProps {
    register: any;
    className?: string;
    type?: string;
    unit?: string;
    readOnly?: boolean;
    required?: boolean;
    transparent?: boolean;
    id?: string;
    placeholder?: string;
}

const Input = ({
    register = {},
    className = '',
    type = 'text',
    unit = '',
    readOnly = false,
    required = false,
    transparent = false,
    placeholder = '',
    ...rest
}: InputProps) => {
    return (
        <div
            className={`${style.input} ${type === 'multiline' ? style.textarea : ''} ${
                readOnly ? style.readOnly : ''
            } ${transparent ? style.transparent : ''} ${className}`}
        >
            {type === 'multiline' && <textarea readOnly={readOnly} placeholder={placeholder} required={required} {...register} {...rest} />}
            {type !== 'multiline' && (
                <input readOnly={readOnly} type={type} required={required} placeholder={placeholder} {...register} {...rest} />
            )}
            {type !== 'multiline' && unit && (
                <label htmlFor={rest.id} className={style.unit}>
                    {unit}
                </label>
            )}
        </div>
    );
};

export default React.memo(Input);
