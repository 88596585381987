import { WithTranslation, withTranslation } from 'react-i18next';
import style from './style.module.css';
import { useContext, useEffect, useState } from 'react';
import Card from '../Card';
import proImg from '../../assets/proImg.png';
import seniorImg from '../../assets/seniorImg.png';
import { UserContext } from '../../providers/UserProvider';
import { getFamilyMembers } from '../../stores/User';
import ListMembers from '../ListMembers';
import { User } from '../../models/User';
import Button from '../Button/index';
import { Share } from '@capacitor/share';
import { Loader } from '../Loader';
import { useNavigation } from '../../pages/ContextMenu';
import { getPro, getSenior } from '../../stores/Establishment';
import { PatientConvert } from '../../models/Patient';

interface GalaxyMemnbresProps extends WithTranslation {
    onReturn?: () => void;
};

const GalaxyMembers = ({ t, onReturn }: GalaxyMemnbresProps) => {
    const { subscribe } = useNavigation();
    const userDataProp = useContext(UserContext);
    const view = ['ADMIN', 'THERAPIST'].includes(userDataProp?.user?.role || '') ? 'pro' : 'user';
    const codeBe = userDataProp?.user?.familyCode;
    const [state, setState] = useState('');
    const [members, setMembers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const handleReturn = () => {
            if (state) {
                setState('');
                return;
            }
            if (onReturn) onReturn();
        };

        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, [state]);

    useEffect(() => {
        const fetchMembers = async () => {
            if (view === 'user') {
                const familyMembers = await getFamilyMembers(userDataProp.token ?? '');
                setMembers(familyMembers);
            } else if (view === 'pro') {
                if (state === 'Professionnels') {
                    const familyMembers = await getPro(userDataProp.token ?? '');
                    setMembers(familyMembers?.data?.map(PatientConvert));
                } else if (state === 'Bénéficiaires') {
                    const beneficiaryMembers = await getSenior(userDataProp.token ?? '');
                    setMembers(beneficiaryMembers?.data?.map(PatientConvert));
                }
            }
        };

        setMembers([]);
        if (userDataProp.token) {
            fetchMembers().then(() => setIsLoading(false));
        }
    }, [view, state, userDataProp.token]);

    if (isLoading) {
        return <Loader />;
    }

    if (view === 'user') {
        return (
            <>
                <div className={`${members.length ? '' : style.center}`}>
                    {members.length ? <ListMembers members={members} /> : <>
                        <div className={style.title}>{t('Code bonne étoile')}</div>
                        <div className={style.codeBe}>{codeBe}</div>
                        <Button label={t('Inviter des bonnes étoiles')} action={() => Share.share({
                            title: "Invitation à rejoindre Stimul'in!",
                            url: `https://appv1.stimulin.fr/register/${codeBe}`,
                        })} />
                    </>}
                </div>
            </>
        );
    }

    return (
        <>
                {state === 'Professionnels' && <>
                    <div className={style.subTitle}>{t('Retrouvez ici la liste des membres de votre communauté de “Bonnes étoiles” professionnelles.')}</div>
                    <ListMembers members={members} />
                </>}

                {state === 'Bénéficiaires' && <>
                    <div className={style.subTitle}>{t('Retrouvez ici la liste des membres de votre communauté de bénéficiaires.')}</div>
                    <ListMembers members={members} />
                </>}

                {!state && <>
                    <div className={style.subTitle}>{t('Sélectionnez un type de membre')}</div>
                    <div className={style.cardContainer}>
                        <Card margin={false} action={() => setState('Professionnels')}>
                            <div className={style.card}>
                                <div className={style.img}>
                                    <img src={proImg} alt="pro" />
                                </div>
                                <div className={style.text}>
                                    <div className={style.cardTitle}>{t('Professionnels')}</div>
                                    <div className={style.cardSubtitle}>{t('Mes collègues, mes homologues, …')}</div>
                                </div>
                            </div>
                        </Card>
                        <Card margin={false} action={() => setState('Bénéficiaires')}>
                            <div className={style.card}>
                                <div className={style.img}>
                                    <img src={seniorImg} alt="senior" />
                                </div>
                                <div className={style.text}>
                                    <div className={style.cardTitle}>{t('Bénéficiaires')}</div>
                                    <div className={style.cardSubtitle}>{t(`Les personnes que j'accompagne`)}</div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </>}
        </>
    );
};

export default withTranslation()(GalaxyMembers);