import axios from 'axios';
import { useEffect, useState } from 'react';
import { Exercise } from '../models/Exercise';
import { GameApiResponse, Game } from '../models/Game';
import { API_URL } from '../config';

const url = API_URL;

export const useGame = (token: string, type: string) => {
    const [data, setData] = useState<Game[] | []>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<GameApiResponse>(`${url}/api/game/${type}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setData(Array.isArray(response.data.data) ? response.data.data : []);
            } catch (error) {
                setError(error instanceof Error ? error : new Error('An error occurred'));
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchData();
        }
    }, [token]);

    return { data, loading, error };
};

export const getGame = async (
    token: string,
    id: string = 'all',
): Promise<any> =>
    axios
        .get(`${url}/api/game/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            console.log(response.data.data);
            return response.data.data as Exercise;
        })
        .catch((error: any) => {
            return null;
        });

const GameType: { [key: string]: string } = {
    'SOUVEN\'IN': 'QUIZ',
    'PUZZL\'IN': 'PUZZLE',
    'MEMOR\'IN': 'MEMORIZ',
    'SUDOK\'IN': 'SUDOKU',
    'PONG\'IN': 'PONG',
    'ORDON\'IN': 'ORDER',
    'TOURBILLON': 'TOURBILLON',
    'MELI-MOTS': 'MELI-MOTS'
}

export const createGame = async (token: string, game: any) => {
    try {
        await axios.post(`${url}/api/game/`,
            {
                title: game.title,
                type: game.type,
                congratulationsMessage: game.congratulationsMessage,
                encouragementMessage: game.encouragementMessage,
                reward: game.reward,
                familyList: game.familyList,
                proList: game.proList,
                media_id_list: game.media,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    } catch (error) {
        throw error instanceof Error ? error : new Error('An error occurred');
    }
};

export const editGame = async (token: string, game: any) => {
    try {
        await axios.put(`${url}/api/game/${game.id}`,
            {
                title: game.exerciseName,
                type: GameType[game.exerciseType],
                congratulationsMessage: game.congratulationsMessage,
                encouragementMessage: game.encouragementMessage,
                reward: game.rewardPhoto || game.rewardVideo,
                familyList: game.patientList,
                proList: game.proList,
                media_id_list: game.exerciseImages
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    } catch (error) {
        throw error instanceof Error ? error : new Error('An error occurred');
    }
};

export const deleteGame = async (token: string, id: string) => {
    try {
        await axios.delete(`${url}/api/game/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error instanceof Error ? error : new Error('An error occurred');
    }
};

// export const useCreateGame = (token: string, type: string) => {
//     const [loading, setLoading] = useState<boolean>(false);
//     const [error, setError] = useState<Error | null>(null);

//     const create = async (name: string) => {
//         setLoading(true);
//         try {
//             await createGame(token, type, name);
//         } catch (error) {
//             setError(error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return { create, loading, error };
// };
