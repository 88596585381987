import { SafeArea } from 'capacitor-plugin-safe-area';

export const PRODUCTION = import.meta.env.VITE_PRODUCTION === 'true';
export const YOUTUBE_API_KEY = PRODUCTION ? import.meta.env.VITE_YOUTUBE_API_KEY_PROD : import.meta.env.VITE_YOUTUBE_API_KEY_DEV;
export const SENTRY_DSN = PRODUCTION ? import.meta.env.VITE_SENTRY_DSN_PROD : import.meta.env.VITE_SENTRY_DSN_DEV;
export const VITE_APP_CONFIG = PRODUCTION ? import.meta.env.VITE_APP_CONFIG_PROD : import.meta.env.VITE_APP_CONFIG_DEV;
export const API_URL = import.meta.env.VITE_API_URL;
export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;

// App device: ANDROID | IOS | PWA | WEB | SW
export const DEVICE = (() => {
    if (import.meta.env.VITE_APP_KIND === 'ANDROID') {
        return 'ANDROID';
    }

    if (import.meta.env.VITE_APP_KIND === 'IOS') {
        const metaElement = document?.querySelector('meta[name="viewport"]');
        if (metaElement) {
            metaElement.setAttribute(
                'content',
                'user-scalable=no, width=device-width, initial-scale=1, maximum-scale=5, viewport-fit=cover'
            );
        }
        return 'IOS';
    }

    if (typeof document !== 'undefined' && sessionStorage.getItem('isPWA')) {
        return 'PWA';
    }

    if (typeof document === 'undefined') {
        return 'SW';
    }

    return 'WEB';
})();

(async () => {
    if (DEVICE === 'IOS') {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            for (const [key, value] of Object.entries(insets)) {
                document.documentElement.style.setProperty(`--safe-area-${key}`, `${Math.floor(value * 0.75)}px`);
            }
            console.log('Multiply by 0.8', insets);
        });

        // when safe-area changed
        const eventListener = await SafeArea.addListener('safeAreaChanged', (data) => {
            const { insets } = data;
            for (const [key, value] of Object.entries(insets)) {
                document.documentElement.style.setProperty(`--safe-area-${key}`, `${Math.floor(value * 0.75)}px`);
            }
        });
        eventListener.remove();
    }
})();

