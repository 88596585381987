import { withTranslation, WithTranslation } from "react-i18next";

import HomeHeader from "../HomeHeader";
import HomeButtonCardGrid from "../HomeButtonCardGrid";
import NavBar from "../NavBar";
import style from "./style.module.css";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import { useMenu } from "../../contexts/MenuContext";
import ContextMenu from "../../pages/ContextMenu";

interface HomeProps extends WithTranslation {
};

const Home = ({ t }: HomeProps) => {
    const userDataProp = useContext(UserContext);
    const role = ['ADMIN', 'THERAPIST'].includes(userDataProp?.user?.role || '') ? 'pro' : 'user';
    const { openMenu, closeMenu } = useMenu();

    const openMenuPage = (page: string) => {
        if (openMenu && closeMenu) {
            openMenu({
                component: () => <ContextMenu onClose={closeMenu} initialPage={page} />,
            });
        }
    }

    return (
        <div className={[style.root, style[role]].join(' ')}>
            <HomeHeader withBanner />
            <span className={style.title}>{t('Bonjour {{firstName}}, que souhaitez-vous faire ?', { firstName: userDataProp?.user?.firstName })}</span>
            <HomeButtonCardGrid openMenu={(page) => openMenuPage(page)} />
            <NavBar />
        </div>
    );
};

export default withTranslation()(Home);