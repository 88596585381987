export class FormField {
    type: 'password' | 'text' | 'email' | 'date' | 'select' | 'number' = 'text';

    title?: string | undefined;

    field: string;

    placeholder?: string | undefined;

    required? = true;

    width: 'FULL' | 'HALF' = 'FULL';

    options?: string[] | undefined;

    dropdownOptions?: string | undefined;

    constructor({
        type,
        title,
        field,
        placeholder,
        required,
        width,
        options,
        dropdownOptions
    }: {
        type?: 'password' | 'text' | 'select' | 'date' | 'email' | 'number';
        title?: string | undefined;
        field: string;
        placeholder?: string | undefined;
        required?: boolean;
        width?: 'FULL' | 'HALF';
        options?: string[];
        dropdownOptions?: string;
    }) {
        this.title = title;
        this.type = type ?? this.type;
        this.field = field;
        this.placeholder = placeholder;
        this.required = required ?? this.required;
        this.width = width ?? this.width;
        this.options = options;
        this.dropdownOptions = dropdownOptions;
    }
}
