import style from './style.module.css';

interface ButtonsContainerProps {
    children: React.ReactNode;
    className?: string;
    position?: 'LEFT' | 'RIGHT' | 'CENTER';
}

const ButtonsContainer = ({ children, className = '', position = 'LEFT' }: ButtonsContainerProps) => {
    return <div className={`${style.buttons} ${style?.[position?.toLowerCase()]} ${className}`}>{children}</div>;
};

export default ButtonsContainer;