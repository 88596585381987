import Gift from './assets/gift.svg?react';
import Heart from './assets/heart.svg?react';
import Albert from './assets/albert.svg?react';
import Pen from './assets/pen.svg?react';
import Image from './assets/image.svg?react';
import Video from './assets/video.svg?react';
import Youtube from './assets/youtube.svg?react';
import style from "./style.module.css";
import { WithTranslation, withTranslation } from "react-i18next";
import Action from '../Action';
import { useState } from 'react';
import { Button } from '../Button/index';
import Cross from '../../assets/cross.svg?react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export interface MediaCardProps {
    id: string;
    title?: string;
    author?: string;
    image: string;
    authorImage?: string;
    media?: 'IMAGE' | 'VIDEO' | 'YOUTUBE';
    permission?: {
        share: boolean;
        delete: boolean;
        write: boolean;
    };
    favorite: boolean;
    new: boolean;
}

interface CardProps extends WithTranslation, MediaCardProps {
    action: () => void;
    onEdit: () => void;
    onDelete: () => void;
};

const MediaCard = ({ t, action, permission, media, title, author, image, authorImage, favorite, new: isNew, onEdit, onDelete, ...rest }: CardProps) => {
    const [selected, setSelected] = useState(false);

    return (
        <Action className={style.root} action={() => action()}>
            {media && <div className={style.media}>
                {media === 'IMAGE' && <Image />}
                {media === 'VIDEO' && <FontAwesomeIcon icon={faVideo} color='white' />}
                {media === 'YOUTUBE' && <FontAwesomeIcon icon={faYoutube} color='white' />}
            </div>}
            {isNew && <div className={style.new}>
                <Gift />
                <span className={style.newTitle}>{t('Nouveau')}</span>
            </div>}
            {!isNew && favorite && <div className={style.favorite}>
                <Heart />
            </div>}
            {!selected && <div className={style.image} style={{ backgroundImage: `url(${image})` }}>
                {title && <span className={style.title}>{title}</span>}
            </div>}
            {selected && <div className={style.image} onClick={(e) => e.stopPropagation()} >
                <Cross className={style.cross} onClick={(e) => { e.stopPropagation(); setSelected(!selected) }} />
                <Button theme='SECONDARY' className={[style.button, style.edit].join(' ')} label={t('Modifier')} action={onEdit} />
                <Button className={[style.button, style.delete].join(' ')} label={t('Supprimer')} action={onDelete} />
            </div>}
            <div className={style.content} onClick={(e) => {
                e.stopPropagation();
                if ((permission?.delete || permission?.write || permission?.share)) {
                    setSelected(!selected)
                }
            }}>
                {authorImage && <img className={[style.authorImage, style.custom].join(' ')} src={authorImage} alt={author} />}
                {!authorImage && <Albert className={style.authorImage} />}
                <span className={[style.author, media ? style.mediaColor : ''].join(' ')}>{t(`De : ${author ? author : 'Albert, Stimul\'in'}`)}</span>
                {(permission?.delete || permission?.write || permission?.share) && <Pen className={style.pen} />}
            </div>
        </Action>
    )
};

export default withTranslation()(MediaCard);