/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * An array shuffling function using the Fisher-Yates shuffle algorithm.
 * @param array An array that I want shuffled
 */
function shuffle(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// eslint-disable-next-line import/no-default-export
export default shuffle;
