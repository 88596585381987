import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import style from './style.module.css';

interface CheckboxProps {
    type?: string;
    title?: string;
    value?: string;
    register?: any;
    disabled?: boolean;
    readOnly?: boolean;
}

const Checkbox = ({type = 'checkbox', title = '', value = '', register = {}, disabled = false, readOnly = false }: CheckboxProps) => {
    return (
        <label className={`${style.field} ${disabled || readOnly ? style.disabled : ''}`}>
            <input
                {...register}
                className={style.input}
                value={value}
                type={type}
                readOnly={readOnly}
                disabled={disabled || readOnly}
            />
            <div className={style.box}>
                <FontAwesomeIcon icon={faCheck} />
            </div>
            {title}
        </label>
    )
};

export default Checkbox;