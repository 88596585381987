import React, { useEffect } from 'react';

import Home from '../components/Home';
import { ScreenOrientation } from '@capacitor/screen-orientation';

const NewHome: React.FC = () => {
    useEffect(() => {
        try {
            ScreenOrientation.unlock();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <Home />
    );
};

export default NewHome;