import _, { set } from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import style from './style.module.css';
import { useForm } from 'react-hook-form';
import FormGroup from '../FormGroup';
import Input from '../Input/index';
import ButtonsContainer from '../ButtonsContainer';
import Button from '../Button/index';
import { useCallback, useEffect, useState } from 'react';
import Action from '../Action';
import { VideoPlayer } from '../VideoPlayer';
import { getVideoFromId, VideoFromYt } from '../../stores/youtube';
import YTSearch from 'youtube-api-search';
import { PRODUCTION, YOUTUBE_API_KEY } from '../../config';

const API_KEY = YOUTUBE_API_KEY;
const delay = PRODUCTION ? 300 : 1000;

interface ImportYoutubeProps extends WithTranslation {
    onReturn: () => void;
    onImport: (data: any) => void;
    onChangeTitle?: (title: string) => void;
    data: any;
};

const ImportYoutube = ({ t, onReturn, onChangeTitle, onImport, data }: ImportYoutubeProps) => {
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState<any>(null);
    const {
        watch,
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { isValid, errors },
    } = useForm<{ video: any, searched: string, thumbnail: string, fileName: string }>({
        mode: 'all',
        defaultValues: {
            video: data?.video || '',
            searched: '',
            thumbnail: data?.thumbnail || '',
            fileName: data?.fileName || '',
        },
    });

    const searched = watch('searched');

    const searchVideos = (searchTerm: string) => {
        YTSearch({ key: API_KEY, term: searchTerm }, (data: any) => {
            setVideos(data);
        });
    };

    const debouncedSearch = useCallback(_.debounce(searchVideos, delay), []);

    useEffect(() => {
        if (searched) {
            debouncedSearch(searched);
        }
    }, [searched, debouncedSearch]);

    useEffect(() => {
        if (selectedVideo) {
            setValue('video', `https://www.youtube.com/watch?v=${selectedVideo.id.videoId}`, { shouldValidate: true });
            setValue('fileName', selectedVideo.snippet.title);
            setValue('thumbnail', selectedVideo.snippet.thumbnails.high.url);
        }
    }, [selectedVideo]);

    const extractVideoId = (url: string) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const validateYouTubeUrlWithAPI = async (url: string) => {
        const videoId = extractVideoId(url);
        if (!videoId) {
            return false;
        }

        return true;
    };

    const onSubmit = async (data: any) => {
        if (!data.thumbnail) {
            const videoId = extractVideoId(data.video) ?? '';
            const video = await getVideoFromId(videoId) as any;
            if (video && (video as VideoFromYt)?.thumbnail) {
                data.thumbnail = video.thumbnail;
            } else {
                setError('video', { type: 'manual' });
                return;
            }
        }
        onImport([{
            url: data.video,
            thumbnail: data.thumbnail,
        }]);
    };

    return (
        <>
            <div className={style.subTitle}>{t('Sélectionnez une vidéo YouTube.')}</div>
            <FormGroup label={t('Recherchez par mot clés') as string}>
                <Input register={register('searched')} placeholder={t('Exemples : "Visite musée" ; "Musique relaxante"') as string} />
            </FormGroup>

            {!!selectedVideo && <>
                <div className={style.selectedVideo}>
                    <div className={style.title}>{selectedVideo.snippet.title}</div>
                    <VideoPlayer url={`https://www.youtube.com/watch?v=${selectedVideo.id.videoId}`} />
                    <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                        <Button size='LARGE' theme='PRIMARY' action={handleSubmit(onSubmit)} label={t('Choisir cette video')} />
                    </ButtonsContainer>
                </div>
            </>}

            {!!videos.length && <>
                <div className={style.videos}>
                    {videos.map((video: any) => (
                        <Action key={video.etag} className={style.video} action={() => setSelectedVideo(video)}>
                            <img src={video.snippet.thumbnails.default.url} alt={video.snippet.title} />
                            <div className={style.title}>{video.snippet.title}</div>
                        </Action>
                    ))}
                </div>
            </>}

            <div className={style.subTitle}>{t('Ou renseignez ici le lien URL qui pointe vers la vidéo Youtube de votre choix.')}</div>
            <FormGroup>
                <Input register={register('video', { required: true, validate: validateYouTubeUrlWithAPI })} placeholder={t('Exemple : https://www.youtube.com/watch?v=XXXXXXXXXXX') as string} />
                {errors.video && <span className={style.error}>{t('URL YouTube invalide')}</span>}
            </FormGroup>

            <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                <Button size='LARGE' theme='SECONDARY' disabled={!isValid} action={handleSubmit(onSubmit)} label={t('Suivant')} />
            </ButtonsContainer>
        </>
    );
};

export default withTranslation()(ImportYoutube);