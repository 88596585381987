import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../Typography';
import ErrorIcon from '../../assets/errorIcon.svg';
import littleArrow from '../../assets/littleArrow.svg';
import { Select } from '../Select';

import {
    SelectTimeline,
    StatToolkit,
    StatsArray,
    addData,
    calcEvol,
    changetimeLine,
    classes,
    getLabelFromDate
} from '../ProfileStatisticsStyle';
import { lstGames } from '../ProfileStatisticsStyle';
import { ExerciseType } from '../../models/ExerciseType';
import { getNumberOfExercises } from '../../services/cloudFirestore';
import { Button } from '../Button';
// import '../../utilities/utils.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Patient } from '../../models/Patient';
import {
    getDateFromTimestamp,
    getDateFromTimestamp2,
    translateMonthByNumber
} from '../../utilities/utils';
import { StatEvol } from './StatEvol';
import { StimbotInfo } from './StimbotInfo';
import { CardLvlDifficult } from './CardLvlDifficult';
import { StatsTimePlay } from './StatsTimePlay';
import { labelsMonth } from '../ProfileStatisticsStyle';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import { getStat } from '../../stores/Stats';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
    userDataList: Patient[];
}

export function StatsGames({ userDataList }: Props): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const [typeDataView, setTypeDataView] = useState<ExerciseType | 'all'>('all');
    const [nbLvlEvol, setNbLvlEvol] = useState<string>('');
    const [nbGames, setNbGames] = useState<number>(0);
    const [statClueCount, setStatClueCount] = useState<number>(0);
    const [statErrorCount, setStatErrorCount] = useState<number>(0);
    const [lvlDificult, setLvlDificult] = useState<number>(0);
    const [endDate, setEndDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    const [startDate, setStartDate] = useState<Date>(
        new Date(endDate.getFullYear(), endDate.getMonth() - 3, 1)
    );
    const [timeScale, setTimeScale] = useState<'day' | 'week' | 'month' | 'year'>('month');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom' as const,
                labels: {
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    precision: 0,
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                border: { dash: [4, 4] },
                grid: {
                    color: '#D8D8D8'
                },
                min: 0
                // max: 10,
                // ticks: {
                //     stepSize: 1
                // }
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                grid: {
                    display: false,
                    lineWidth: 0
                }
            }
        }
    };

    const [data, setData] = useState<any | undefined>({
        labels: labelsMonth,
        datasets: [
            {
                label: t('gamesLvl'),
                data: [],
                backgroundColor: '#748DE8',
                borderRadius: 20,
                width: 5
            }
        ]
    });

    const calcData = async () => {
        const res = await getStat(
            userDataProp?.token || '',
            'game/difficulty',
            {
                startDate,
                endDate,
                idList: userDataList.map((user) => user.id ?? ''),
                timeScale,
                type: typeDataView
            }
        ).then((res) => res.data);

        setNbLvlEvol(calcEvol(res.data, timeScale)),
            setStatClueCount(
                res.totalClue.toFixed(0) || 0
            );
        setLvlDificult(
            res.totalDifficulty.toFixed(0) || 0
        );
        setStatErrorCount(
            res.totalError.toFixed(0) || 0
        );

        setNbGames(res.data.reduce((a: any, b: any) => a + b, 0));
        setData({
            ...data,
            labels: getLabelFromDate(startDate, endDate, timeScale),
            datasets: [
                {
                    ...data.datasets[0],
                    data: res.data.map((d: any) =>  d ? d.toFixed(0) : 0)
                }
            ]
        });
    };

    useEffect(() => {
        if (userDataList && userDataList.length > 0) {
            calcData();
        }
    }, [userDataList, typeDataView, startDate, endDate, timeScale]);

    return (
        <div style={{ ...classes['stat-card-container'] }}>
            <div style={{ ...classes['stat-card-title-container'] }}>
                <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.gamesTitle')}
                </Typography>
                {/* <StatToolkit value={nbGames}/> */}
            </div>
            <div style={{ ...classes['stat-card'] }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                    <Typography variant="p" sx={{ ...classes['stat-card-subtitle'] }}>
                        {t('profile.statistics.gamesSubtitle')}
                    </Typography>
                    <StatToolkit
                        value={lvlDificult}
                        tooltipText={t('profile.statistics.gamesTooltip', { nb: lvlDificult })}
                    />
                </div>
                <StimbotInfo text={t('profile.statistics.gamesInfo')} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '1rem'
                    }}>
                    <Select
                        sxRoot={{ ...classes['select-white-root'] }}
                        sx={{
                            border: '1px solid #E8E8E8',
                            borderRadius: '1.5rem'
                        }}
                        defaultValue="all"
                        value={typeDataView}
                        opts={[
                            { value: 'all', label: t('all') },
                            ...lstGames.map((game, index) => {
                                return { value: game.type, label: t(game.name) };
                            })
                        ]}
                        onChange={(e) => setTypeDataView(e.target.value as ExerciseType)}
                    />
                    <SelectTimeline
                        value={timeScale}
                        onChange={(data: {
                            val: string;
                            startDate: Date;
                            endDate: Date;
                            timeScale: 'day' | 'week' | 'month' | 'year';
                        }) => {
                            setEndDate(data.endDate);
                            setStartDate(data.startDate);
                            setTimeScale(data.timeScale);
                        }}
                    />
                </div>
                {data && <Bar options={options} data={data} />}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1.5rem',
                        width: '100%'
                    }}>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'prev',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        <img src={littleArrow} alt="littleArrow" />
                        {t('prev')}
                    </Button>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'next',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        {t('next')}
                        <img
                            src={littleArrow}
                            alt="littleArrow"
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    </Button>
                </div>
                {(typeDataView === 'all' ||
                    [
                        ExerciseType.MEMORIZ,
                        ExerciseType.ORDER,
                        ExerciseType.PUZZLE,
                        ExerciseType.QUIZ,
                        ExerciseType.SUDOKU
                    ].includes(typeDataView)) && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '0.75rem'
                        }}>
                        {/* <img
                            src={clueIcon}
                            alt="clueIcon"
                            style={{ height: '2.5rem', width: '2.5rem' }}
                        /> */}
                        <div
                            style={{
                                minWidth: '2.5rem',
                                maxWidth: '2.5rem',
                                minHeight: '2.5rem',
                                maxHeight: '2.5rem',
                                borderRadius: '50%',
                                backgroundColor: '#009F541A',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <svg
                                style={{ height: '1.8rem', width: '1.8rem' }}
                                className=""
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                fill="#009F54"
                                data-testid="HelpOutlinedIcon">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"></path>
                            </svg>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Typography
                                variant="p"
                                sx={{
                                    color: '#0D224B',
                                    fontSize: '1rem',
                                    fontWeight: '600'
                                }}>
                                {t('profile.statistics.clueCountUsed')}
                            </Typography>
                            <Typography
                                variant="p"
                                sx={{
                                    color: '#6B6B6B',
                                    fontSize: '0.75rem',
                                    fontWeight: '500'
                                }}>
                                {t('averageNb')}
                            </Typography>
                        </div>
                        <span
                            style={{
                                minWidth: '2.5rem',
                                minHeight: '2.5rem',
                                width: '2.5rem',
                                height: '2.5rem',
                                borderRadius: '50%',
                                border: '1px solid #E8E8E8',
                                color: '#0D224B',
                                fontSize: '1.125rem',
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            {statClueCount}
                        </span>
                    </div>
                )}

                {(typeDataView === 'all' ||
                    [
                        ExerciseType.MEMORIZ,
                        ExerciseType.ORDER,
                        ExerciseType.QUIZ,
                        ExerciseType.SUDOKU
                    ].includes(typeDataView)) && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '0.75rem'
                        }}>
                        <img
                            src={ErrorIcon}
                            alt="ErrorIcon"
                            style={{ height: '2.5rem', width: '2.5rem' }}
                        />
                        <div style={{ width: '100%' }}>
                            <Typography
                                variant="p"
                                sx={{
                                    color: '#0D224B',
                                    fontSize: '1rem',
                                    fontWeight: '600'
                                }}>
                                {t('profile.statistics.errorCountUsed')}
                            </Typography>
                            <Typography
                                variant="p"
                                sx={{
                                    color: '#6B6B6B',
                                    fontSize: '0.75rem',
                                    fontWeight: '500'
                                }}>
                                {t('averageNb')}
                            </Typography>
                        </div>
                        <span
                            style={{
                                minWidth: '2.5rem',
                                minHeight: '2.5rem',
                                width: '2.5rem',
                                height: '2.5rem',
                                borderRadius: '50%',
                                border: '1px solid #E8E8E8',
                                color: '#0D224B',
                                fontSize: '1.125rem',
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            {statErrorCount}
                        </span>
                    </div>
                )}
                <StatEvol evolNb={nbLvlEvol} />
            </div>
            {/* <CardLvlDifficult userData={userData} /> */}
            <StatsTimePlay userDataList={userDataList} />
        </div>
    );
}
