import axios from 'axios';
import { YOUTUBE_API_KEY } from '../config';

const API_URL = 'https://www.googleapis.com/youtube/v3';
const API_KEY = YOUTUBE_API_KEY;

export class VideoFromYt {
    id: string;
    title: string;
    description: string;
    thumbnail: string;
    channelTitle: string;

    constructor(
        id: string,
        title: string,
        description: string,
        thumbnail: string,
        channelTitle: string
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.thumbnail = thumbnail;
        this.channelTitle = channelTitle;
    }
}

export const getVideoListFromTitle = async (
    title: string,
    pageToken: string | undefined = undefined
): Promise<{ lst: VideoFromYt[]; nextPageToken: string } | 'error'> => {
    const ListVideos: VideoFromYt[] = [];
    let nextPageToken: string = '';
    await axios
        .get(`${API_URL}/search`, {
            params: {
                part: 'snippet',
                type: 'video',
                videoDefinition: 'standard',
                maxResults: 10,
                embeddable: true,
                q: title,
                key: API_KEY,
                pageToken: pageToken
            }
        })
        .then((response: any) => {
            response.data.items.forEach((item: any) => {
                ListVideos.push({
                    id: item.id.videoId,
                    title: item.snippet.title,
                    description: item.snippet.description,
                    thumbnail: item.snippet.thumbnails.high.url,
                    channelTitle: item.snippet.channelTitle
                });
            });
            nextPageToken = response.data.nextPageToken;
            return { lst: ListVideos, nextPageToken: response.data.nextPageToken };
        })
        .catch((error: any) => {
            return 'error';
        });
    return { lst: ListVideos, nextPageToken: nextPageToken };
};

export const getVideoFromId = async (id: string): Promise<VideoFromYt | 'error'> => {
    let video: VideoFromYt[] = [];
    await axios
        .get(`${API_URL}/videos`, {
            params: {
                part: 'snippet',
                id: id,
                key: API_KEY
            }
        })
        .then((response: any) => {
            video.push({
                id: response.data.items[0].id,
                title: response.data.items[0].snippet.title,
                description: response.data.items[0].snippet.description,
                thumbnail: response.data.items[0].snippet.thumbnails.high.url,
                channelTitle: response.data.items[0].snippet.channelTitle
            });
            return video;
        })
        .catch((error: any) => {
            return 'error';
        });
    return video[0] ?? 'error';
};
