import { GRID, NUMBERS } from '../../../typings';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IInput {
    grid: GRID;
    row: number;
    value: NUMBERS;
}

/**
 * a function that returns true if the value is already being used in the current grid row
 * @param input Object with 9x9 sudoku grid, row index, and value
 */
function isInRow({ grid, row, value }: IInput): boolean {
    return grid[row].includes(value);
}

// eslint-disable-next-line import/no-default-export
export default isInRow;
