import { User } from '../../models/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ProfilePicture from '../ProfilePicture';
import { Patient } from '../../models/Patient';

import style from './style.module.css';

interface CheckMemberProps {
    member: User | Patient;
    value?: string;
    register?: any;
    disabled?: boolean;
    readOnly?: boolean;
};

const CheckMember = ({ member, value = '', register = {}, disabled = false, readOnly = false }: CheckMemberProps) => {
    const getInitials = (member: User) => {
        const firstName = member.firstName;
        const lastName = member.lastName
        return (firstName?.charAt(0).toUpperCase() ?? '') + (lastName?.charAt(0).toUpperCase() ?? '');
    };

    return (
        <label className={`${style.field} ${disabled || readOnly ? style.disabled : ''}`}>
            <input
                {...register}
                className={style.input}
                value={value}
                type={'checkbox'}
                readOnly={readOnly}
                disabled={disabled || readOnly}
            />
            <div className={style.profilePictureContainer}>
                <ProfilePicture picture={member.profilePhoto as string} initials={getInitials(member)} />
            </div>
            <div className={style.memberName}>{member.firstName} {member.lastName}</div>
            <div className={style.box}>
                <FontAwesomeIcon icon={faCheck} />
            </div>
        </label>
    );
};

export default CheckMember;